import { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getProductBySellerId } from "redux/product";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { titelize } from "utils/titelize";
import React, {Suspense} from "react";
import SingleProductLayout from "../../ExploreProductsPage/searchResults/SingleProductLayout";
import { addToWishListStart, removeFromWishListStart } from "redux/wishlist";
import { IWislistItemsMap } from "../../Home/ProductPost/types";
import { wishlistSelector } from "redux/selectors/wishlist";
import Snack from "components/wrapper/snack";
import { cartItemsSelector } from "redux/selectors/cart-items";
import { addToCartStart, removeFromCartStart } from "redux/cart";



const useStyles = makeStyles((theme) => ({
  item: {
    boxShadow: "none",
    transition: "1s",
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
        transition: "1s",
      },
  },
},
}));

const UserListings = (props: any) => {
  const dispatch = useDispatch();
  const data: any = useSelector((state: RootState) => state.product.products);
  const totalProducts: any = useSelector(
    (state: RootState) => state.product.productsLength
  );
  const classes = useStyles();
  const history = useHistory();
  const [sorting, setSorting] = React.useState<any>(
    {price:null}
  );
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const wishlistItems = useSelector(wishlistSelector);
  const cartItems: any = useSelector(cartItemsSelector);
  const [qty] = useState(1);


  const handleClick = (item: any) => {
    history.push({
      pathname: `/product-page/${item._id}/${titelize(item.title)}`,
      state: {
        item: item,
        src: "from-search",
      },
    });
  };
  const handleEditIcon = (item: any) => {
    history.push({
      pathname: `/edit-product/${item._id}`,
    });
  };

  const handleWL = (id:string, title:string, saleprice:string) => {
    const data:any = wishlistItems.find((wl: any) => wl._id === id);
    if (data) {
      dispatch(removeFromWishListStart(data.wishlistId))
      Snack.success("Item removed from Wishlist!");
      return;
    }
    const _product: any = {};
    _product.productId = id ? id : id;
    _product.name = title;
    _product.price = Number(saleprice);
    _product.type = "wishlist";
    _product.total = Number(saleprice);

    dispatch(addToWishListStart(_product));
    Snack.success("Item added to Wishlist");
  };

  const wisListMap: IWislistItemsMap = useMemo(() => {
    let map = {}
    wishlistItems.forEach((item: any) => {
      map = {
        ...map,
        [item._id]: item
      }
    })
    return map
  }, [wishlistItems])

  const cartListMap = useMemo(() => {
    let map = {}
    cartItems.forEach((item : any) => {
      map = {
        ...map,
        [item._id] :item
      }
    })
    return map
  }, [cartItems]);

  const isInWishlist = (id: any) => {
    if (wisListMap[id]) return true;
    return false;
  };

  useEffect(() => {
    const user = {
      sellerId: props.userId,
      page: pageNumber,
      sort:sorting
    };
    dispatch(getProductBySellerId(user));
  }, [props.userId]);


  const handleAddToCart = (product: any) => {
    const {id, title, saleprice} = product;
    const _product: any = {};
    if (cartItems.length !== 0) {
      _product.quantity = qty;
    } else {
      const cartItem = cartItems.find((item: any) => item._id === id);
      if (cartItem) {
        if (cartItem.cartQty === 5 && (qty === 5 || qty > 5)) {
          _product.quantity = 5;
        }

        if (cartItem.cartQty <= 5 && qty <= 5) {
          if (cartItem.cartQty + qty >= 5) {
            _product.quantity = 5;
          }
          if (cartItem.cartQty + qty < 5) {
            _product.quantity = cartItem.cartQty + qty;
          }
        }
      } else {
        _product.quantity = qty >= 1 && qty <= 5 ? qty : 1;
      }
    }
    const data:any = cartItems.find((e: any) => e._id === id);

    if(data){
      dispatch(removeFromCartStart(data.cartId))
      Snack.success("Item removed from Cart!");
      return;
    }

    _product.productId = id ? id : product['_id'];;
    _product.name = title;
    _product.price = Number(saleprice);
    _product.type = "cart";
    _product.total = Number(saleprice) * _product.quantity;
    dispatch(addToCartStart({ _product, product}));
    Snack.success("Item added in cart");
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div style={{ display: "flex", padding: "8px" }}>
        <h4>Sort By: </h4> &nbsp;&nbsp;&nbsp;&nbsp;
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Price</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              style={{ width: "200px"}}
              id="demo-simple-select"
              value={sorting}
              label="Price"
              onChange={(e: any) => {
                setSorting(e.target.value);
                const user = {
                  sellerId: props.userId,
                  page: pageNumber,
                  sort:{...sorting, price:e.target.value}
                };
                dispatch(getProductBySellerId(user));
              }}
            >
              <MenuItem value={"asc"}>Low to High</MenuItem>
              <MenuItem value={"desc"}>High to Low</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
  <Grid container spacing={2} justifyContent="flex-start">
    {data?.map((product: any) => (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        className={classes.item}
        sx={{ transition: "box-shadow" }}
      >
        <Suspense fallback={<div>Loading product...</div>}>
          <SingleProductLayout
            product={product}
            handleClick={handleClick}
            handleWL={handleWL}
            handleAddToCart={handleAddToCart}
            editIcon={props.editIcon}
            handleEditIcon={handleEditIcon}
            handleOpen={props.handleOpen}
            isInWishlist={isInWishlist(product._id)}
          />
        </Suspense>
      </Grid>
    ))}
  </Grid>
  <Grid
    item
    xs={12}
    sm={12}
    md={12}
    lg={12}
    style={{ margin: "auto", paddingTop: "25px", width: "30%" }}
    sx={{ transition: "box-shadow" }}
  >
  </Grid>
</Grid>

    </Grid>
  );
};

export default UserListings;
