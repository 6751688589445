import * as React from 'react';
import { Icon, Link } from '@mui/material';
import styled from 'styled-components';

// external application icons
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import '../../../styles/common.css';
import '../../../styles/responsive-common.css';
import { useHistory } from "react-router-dom";
import { TIKTOKICON } from 'assets2/images';

// keep the footer fixed so it shows all the time (except on mobile)
const ContainerFluid = styled.div`
  position: fixed;
  z-index:999;
  bottom:0;
  background-color: #0066FF;
  height: 39px;

  @media (max-width: 600px) {
    position: static; 
  }
`;

//position styling and formatting

const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 911px) {
    display: none;
  }
`;

const SocialMediaIcon = styled.div`
  margin-top: -8px; 

  @media (max-width: 750px;) {
    margin-top: 10px;
  }
`;

const TikTokIconMove = styled.div`
  margin-top: -5px;

  @media (max-width: 768px) {
    margin: 0 5px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    margin: 0 1px;
    font-size: 8px; /* Adjust the font size for even smaller screens */
  }
`

const FooterLinks = styled.div`

  @media (max-width: 768px) {
    margin-top: -3px;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    text-align: center;
  }

  @media (max-width: 300px;) {
    font-size: 5px;
  }
`;

const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 10px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 300px;) {
    font-size: 5px;
  }
`;

const Footer: React.FC = () => {
  const history = useHistory();
  return (
    <footer>
      <ContainerFluid className="container-fluid">
        <div className="find-out-section">
          <div className="d-flex w-100 justify-content-between">
            <div className="alphabetically-wrap">
              <FooterLinks className="footer-links">
                {/* links */}
                <FooterLink>
                <Link
                  className='cursorPointer'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/about-us" });
                  }}
                  
                >
                  About
                </Link>
                </FooterLink>
                <FooterLink>
                <Link
                  className='cursorPointer'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/sell-on-resell" });
                  }}
                >
                  Sell on Resell
                </Link>
                </FooterLink>
                <FooterLink>
                <Link
                  className='cursorPointer'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/prohibited-items" });
                  }}
                >
                  Prohibited Items
                </Link>
                </FooterLink>

                <FooterLink>
                <Link
                  className='cursorPointer'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/sustainability" });
                  }}
                >
                  Sustainability
                </Link>
                </FooterLink>
                <FooterLink>
                <Link
                  className='cursorPointer'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/contact-us" });
                  }}
                >
                  Support
                </Link>
                </FooterLink>
                <FooterLink>
                <Link
                  className='cursorPointer'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/blog" });
                  }}
                >
                  Blog
                </Link>
                </FooterLink>
                <FooterLink>
                <Link
                  className='cursorPointer'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/privacy-policy" });
                  }}
                >
                  Terms
                </Link>
                </FooterLink>
                <FooterLink>
                <Link
                  className='cursorPointer'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push({ pathname: "/privacy-policy" });
                  }}
                >
                  Privacy
                </Link>
                </FooterLink>
              </FooterLinks>

              <SocialMediaContainer className="social-media-container">
              <SocialMediaIcon>
                <a
                  className="social-media"
                  target="blank"
                  href="https://www.youtube.com/@resellmarketplaceny"
                  aria-label="YouTube Icon"
                >
                    <YouTubeIcon />
                </a>
                </SocialMediaIcon>

                <SocialMediaIcon>
                <a
                  className="social-media"
                  target="blank"
                  href="https://www.instagram.com/resellmarketplacenyc/"
                  aria-label="Instagram Icon"
                >
                    <InstagramIcon />
                </a>
                </SocialMediaIcon>

                <SocialMediaIcon>
                <a
                  className="social-media"
                  target="blank"
                  href="https://www.facebook.com/people/ReSell-NY/100083370759187/"
                  aria-label="Facebook Icon"
                >
                    <FacebookIcon />
                </a>
                </SocialMediaIcon>

                <SocialMediaIcon>
                <a
                  className="social-media"
                  target="blank"
                  href="https://twitter.com/resell_marketpl"
                  aria-label="Twitter Icon"
                >
                   <TwitterIcon />
                </a>
                </SocialMediaIcon>
                
                <TikTokIconMove>
                <a
                  className="social-media"
                  target="blank"
                  href="https://www.tiktok.com/@resellmarketplace"
                  aria-label="Tiktok Icon"
                >
                  <img src={TIKTOKICON} alt="no-img"/>
                </a>
                </TikTokIconMove>
              </SocialMediaContainer>
            </div>
          </div>
        </div>
      </ContainerFluid>
    </footer>
  );
};

export default Footer;
