import { useEffect, useReducer, useCallback } from "react";

const INTERSECTION_THRESHOLD = 5;

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "set": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "onGrabData": {
      if (action.payload && action.payload.data) {
        let array3: any[] = [];
        if (state.updatedData.length === 0) {
          array3 = [...state.updatedData, ...action.payload.data];
        } else {
          let array4 = state.updatedData.filter(
            (obj: any) => action.payload.data.indexOf(obj) === -1
          );
          if (array4.length === 0) {
            array3 = [...state.updatedData];
          } else {
            array3 = [...state.updatedData, ...array3];
          }
        }

        return {
          ...state,
          loading: false,
          updatedData: array3,
          // state.updatedData.filter(function(obj:any) { return action.payload.data.indexOf(obj) == -1; }),
          //  [...state.updatedData, ...action.payload.data],
          currentPage: state.currentPage + 1,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};

const useLazyLoad = (props: any) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    currentPage: 1,
    updatedData: [],
  });
  if (props.resetDataSet) {
    state.loading = false;
    state.currentPage = 1;
    state.updatedData = [];
  }

  const _handleEntry = useCallback(
    async (entry: any) => {
      const boundingRect = entry.boundingClientRect;
      const intersectionRect = entry.intersectionRect;

      if (
        !state.loading &&
        entry.isIntersecting &&
        intersectionRect.bottom - boundingRect.bottom <= INTERSECTION_THRESHOLD
      ) {
        dispatch({ type: "set", payload: { loading: true } });
        const data = await props.onGrabData(state.currentPage);
        dispatch({ type: "onGrabData", payload: { data } });
      }
    },
    [props.onGrabData]
  );

  const onIntersect = useCallback(
    (entries) => {
      _handleEntry(entries[0]);
    },
    [_handleEntry]
  );

  useEffect(() => {
    if (props.triggerRef.current) {
      const container = props.triggerRef.current;
      const observer = new IntersectionObserver(onIntersect);

      observer.observe(container);

      return () => {
        observer.disconnect();
      };
    }
  }, [onIntersect]);

  return state;
};

export default useLazyLoad;
