// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./components/wrapper/snack";

import "./customStyles/scss/react-phoneInput2.scss";

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider hideIconVariant>
      <SnackbarUtilsConfigurator />
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
