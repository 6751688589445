import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserListings from "../../Dashboard/UserListing/userListings";
import "../style.css";
import UserShop from "./userShop";
import { useDispatch, useSelector } from "react-redux";
import { getUserShopByIdStart } from "redux/userShops";

import { RootState } from "redux/store";


const ShopListProduct = () => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [item, setItem] = useState(null);

  useEffect(() => {
    setId(location?.state?.item?._id);
    }, [location]);

  const shopById = useSelector(
    (state: RootState) => state.userShop.shopById
  );

  useEffect(() => {
    if (location?.state?.item?.bannerImages === undefined) {
      dispatch(getUserShopByIdStart(id));
    }
  }, [id, dispatch, location]);

  useEffect(() => {
    setItem(shopById);
  }, [shopById]);

  return <> {id !== null ?
    <UserShop  user={location?.state?.item?.bannerImages === undefined ? item : location?.state?.item}/> : null}</>;
};

export default ShopListProduct;
