import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  usePagination,
  useResultsPerPage,
  useSearchContext,
} from "@sajari/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { titelize } from "utils/titelize";
import useLazyLoad from "./useLazyLoad";
import { addToWishListStart, getWishListItemsStart, removeFromWishListStart } from "redux/wishlist";
import { addToCartStart, removeFromCartStart } from "redux/cart";
import { wishlistSelector } from "redux/selectors/wishlist";
import { cartItemsSelector } from "redux/selectors/cart-items";
import Snack from "components/wrapper/snack";
import { IWislistItemsMap } from "components/Layout/SiteContent/Home/ProductPost/types";

const useStyles = makeStyles((theme) => ({
  item: {
    boxShadow: "none",
    transition: "1s",
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
        transition: "1s",
      },
    },
  },
}));

const useProduct = () => {
  const history = useHistory();
  const [modalProps, setModalProps] = useState<any>({id:'',title:''});
  const { totalResults, results }: any = useSearchContext();
  const triggerRef: any = useRef(null);
  const [open, setOpen] = useState(false);
  const { setResultsPerPage } = useResultsPerPage();
  const [resetDataSet, setResetDataSet] = useState(false);
  const { setPage } = usePagination();
  const [qty] = useState(1);
  const wishlistItems = useSelector(wishlistSelector);
  const cartItems: any = useSelector(cartItemsSelector);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setResultsPerPage(Number(100));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setResetDataSet(true);
  }, [totalResults]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getWishListItemsStart());
  }, [dispatch]);

  const onGrabData = useCallback(
    async (currentPage: number) => {
      setPage(currentPage);

      return new Promise((resolve) => {
        resolve(results);
        setResetDataSet(false);
      });
    },
    [results, setPage]
  );

  const wisListMap: IWislistItemsMap = useMemo(() => {
    let map = {}
    wishlistItems.forEach((item: any) => {
      map = {
        ...map,
        [item._id]: item
      }
    })
    return map
  }, [wishlistItems])

  const cartListMap = useMemo(() => {
    let map = {}
    cartItems.forEach((item : any) => {
      map = {
        ...map,
        [item._id] :item
      }
    })
    return map
  }, [cartItems]);

  const { updatedData, loading } = useLazyLoad({
    triggerRef,
    onGrabData,
    resetDataSet,
  });

  const handleWL = (id:string, title:string, saleprice:string) => {
    const data:any = wishlistItems.find((wl: any) => wl._id === id);
    if (data) {
      dispatch(removeFromWishListStart(data.wishlistId))
      Snack.success("Item removed from Wishlist!");
      return;
    }
    const _product: any = {};
    _product.productId = id ? id : id;
    _product.name = title;
    _product.price = Number(saleprice);
    _product.type = "wishlist";
    _product.total = Number(saleprice);

    dispatch(addToWishListStart(_product));
    Snack.success("Item added to Wishlist");
  };

  const handleAddToCart = (product: any) => {
    const {id, title, saleprice} = product;
    const _product: any = {};
    if (cartItems.length !== 0) {
      _product.quantity = qty;
    } else {
      const cartItem = cartItems.find((item: any) => item._id === id);
      if (cartItem) {
        if (cartItem.cartQty === 5 && (qty === 5 || qty > 5)) {
          _product.quantity = 5;
        }

        if (cartItem.cartQty <= 5 && qty <= 5) {
          if (cartItem.cartQty + qty >= 5) {
            _product.quantity = 5;
          }
          if (cartItem.cartQty + qty < 5) {
            _product.quantity = cartItem.cartQty + qty;
          }
        }
      } else {
        _product.quantity = qty >= 1 && qty <= 5 ? qty : 1;
      }
    }
    const data:any = cartItems.find((e: any) => e._id === id);

    if(data){
      dispatch(removeFromCartStart(data.cartId))
      Snack.success("Item removed from Cart!");
      return;
    }

    _product.productId = id ? id : product['_id'];
    _product.name = title;
    _product.price = Number(saleprice);
    _product.type = "cart";
    _product.total = Number(saleprice) * _product.quantity;
    dispatch(addToCartStart({ _product, product}));
    Snack.success("Item added in cart");
  };

  const handleClick = (item: any) => {
    history.push({
      pathname: `/product-page/${item.id}/${titelize(
        item.title
      )}`,
      state: {
        item: item.values,
        src: "from-search",
      },
    });
  };

  const handleOpen = (e:any) => {
    setModalProps(e);
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  return {
    handleWL,
    handleAddToCart,
    handleClick,
    handleOpen,
    handleClose,
    setOpen,
    updatedData,
    open,
    modalProps,
    triggerRef,
    results,
    loading,
    classes,
    wisListMap,
    cartListMap
  }
}

export default useProduct
