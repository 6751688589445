import {_axiosInstance} from "api/axiosController";

export const getGamesProducts = async (query: number) => {
  let uri = `/search/v1/get?query=games`;
  try {
    const res:any = await _axiosInstance.get(uri);
    console.log ({games : res })
    return res.data;
  } catch (e) {
    throw e;
  }
};

