import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  addNewAddressFailure,
  addNewAddressStart,
  addNewAddressSuccess,
  getAddressesStart,
  removeAddressStart,
  setAddressAsDefaultStart
} from "redux/address";
import {
  updateNewAddress,
  getAddresses,
  removeAddress,
  setAddressAsDefault
} from "redux/services/address";
import { getAccessToken } from "utils/auth";
import snack from "components/wrapper/snack";
import { history } from 'routes/'

function* addressSagaWatcher() {
  yield takeLatest(
    [addNewAddressStart.type,
    getAddressesStart.type,
    removeAddressStart.type,
    setAddressAsDefaultStart.type],
    addressWorker
  );
}

function* addressWorker(action: any): any {
  try {
    if (getAccessToken()) {
      switch (action.type) {
        case addNewAddressStart.type: {
          const response: any = yield call(updateNewAddress, action.payload.address);
          // yield put(addNewAddressSuccess({ addresses: response.ResponseBody }));
          if(action.payload.nextPath) {
            history.push(action.payload.nextPath);
          }
          yield put(getAddressesStart());
        }
          break;

        case removeAddressStart.type:
          yield call(removeAddress, action.payload);
          snack.error('Address removed Successfully')
          yield put(getAddressesStart());
          break;

        case setAddressAsDefaultStart.type:
          yield call(setAddressAsDefault, action.payload);
          snack.success('Default Address set Successfully')
          yield put(getAddressesStart());
          break;

        case getAddressesStart.type: {
          const response: any = yield call(getAddresses);
          yield put(addNewAddressSuccess({ addresses: response.ResponseBody }));
        }
        break;

        default:
          break;
      }
    } else {
      console.error('Unauthorized User');
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === addNewAddressStart.type || getAddressesStart.type) {
      yield put(addNewAddressFailure);
    }
  }
}

export default addressSagaWatcher;
