import snack from "components/wrapper/snack";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getUserShopsByListingsStart,
  getUserShopsByListingsSuccess,
  getUserShopsByListingsFailure,
  getUserShopByIdStart,
  getUserShopByIdSuccess
} from 'redux/userShops';
import { getUserShopById, getUserShopsByListings } from "redux/services/userShop";

function* UserShopsSagaWatcher() {
  yield takeLatest(
    [
      getUserShopsByListingsStart.type,
      getUserShopByIdStart.type
    ],
    userShopsWorker,
  );
}

function* userShopsWorker(action: any): any {
 /* try { */
      switch (action.type) {
 
          case getUserShopsByListingsStart.type:{
            const response:any = yield call(getUserShopsByListings);
            yield put(getUserShopsByListingsSuccess({ userShops: response.ResponseBody }));
          }
            break; 
        
          case getUserShopByIdStart.type:{
            const response:any = yield call(getUserShopById, action.payload);
            yield put(getUserShopByIdSuccess({ shopById: response.ResponseBody[0] }));
          }
            break;
    default:
      break;

      }

/*  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getUserShopsByListingsStart.type) {
      yield put(getUserShopsByListingsFailure);
    } 
  } */
}

export default UserShopsSagaWatcher;
