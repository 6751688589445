import React from "react";
import LazyLoadWrapper from "components/wrapper/lazy-load-wrapper";
import ROUTE_PATHS from "./routesPath";
import { route } from "types/routes";
import ShopResults from "components/Layout/SiteContent/ShopHome";
import ShopListProduct from "components/Layout/SiteContent/ShopHome/Shop/ShopListProduct";

const EditProduct = React.lazy(
  () => import("components/Layout/SiteContent/Product/editProduct")
);
const Home = React.lazy(() => import("components/Layout/SiteContent/Home"));
const ProductPage = React.lazy(
  () => import("components/Layout/SiteContent/Product")
);
const ProductCategories = React.lazy(
  () => import("components/Layout/SiteContent/ProductCategories")
);
const PayPalCheckout = React.lazy(
  () => import("components/Layout/SiteContent/Payment/PayPalCheckout")
);
const PaymentSection = React.lazy(
  () => import("components/Layout/SiteContent/Payment")
);
const PaymentStatus = React.lazy(
  () => import("components/Layout/SiteContent/Payment/PaymentStatus")
);
const StripePaymentStatus = React.lazy(
  () => import("components/Layout/SiteContent/Payment/StripePaymentStatus")
);
const RatingReview = React.lazy(
  () => import("components/Layout/SiteContent/RatingReview")
);
const Listing = React.lazy(
  () => import("components/Layout/SiteContent/Listing")
);
const Blog = React.lazy(
  () => import("components/Layout/SiteContent/Products/blog")
);
const BuyerSignUp = React.lazy(
  () => import("components/Layout/SiteContent/Signup/buyer")
);
const SellerSignUp = React.lazy(
  () => import("components/Layout/SiteContent/Signup/seller")
);
const SignIn = React.lazy(() => import("components/Layout/SiteContent/Signin"));
const GuestLogin = React.lazy(
  () => import("components/Layout/SiteContent/Signin/guestLogin")
);
const Dashboard = React.lazy(
  () => import("components/Layout/SiteContent/Dashboard/dashboard")
);
const VerifyEmail = React.lazy(() => import("components/Account/verifyEmail"));
const ResendEmail = React.lazy(() => import("components/Account/resendEmail"));
const ForgotPassword = React.lazy(
  () => import("components/Account/forgotPassword")
);
const ResetPasswordForm = React.lazy(
  () => import("components/Account/forgotPassword/resetPasswordForm")
);
const GiftCard = React.lazy(
  () => import("components/Layout/SiteContent/GiftCard")
);
const AffSignUp = React.lazy(
  () => import("components/Layout/SiteContent/Affliate/signup")
);
const AffSignIn = React.lazy(
  () => import("components/Layout/SiteContent/Affliate/signin")
);
const ContactUs = React.lazy(
  () => import("components/Layout/SiteContent/ContactUs")
);
const AboutUs = React.lazy(
  () => import("components/Layout/SiteContent/AboutUs")
);
const AffiliateHome = React.lazy(
  () => import("components/Layout/SiteContent/Affliate/affiliateHome")
);
const ExploreProductsPage = React.lazy(
  () => import("components/Layout/SiteContent/ExploreProductsPage")
);
const PaymentProcess = React.lazy(
  () => import("components/Layout/SiteContent/PaymentProcess")
);
const OrderTracking = React.lazy(
  () => import("components/Layout/SiteContent/OrderTracking")
);
const MyOrders = React.lazy(
  () => import("components/Layout/SiteContent/MyOrders")
);
const AccountSettings = React.lazy(
  () => import("components/Layout/SiteContent/AccountSettings/accountSettings")
);
const EditAddress = React.lazy(
  () => import("components/Layout/SiteContent/PaymentProcess/editAddress")
);
const Terms = React.lazy(
  () => import("components/Layout/SiteContent/Terms")
  );
const ProhibitedItems = React.lazy(
  () => import("components/Layout/SiteContent/ProhibitedItems")
  );
const Sustainability = React.lazy(
  () => import("components/Layout/SiteContent/Sustainability")
  );
const SellingSitemap = React.lazy(
  () => import("components/Layout/SiteContent/SellingSitemap")
);
const SellOnResell = React.lazy(
  () => import("components/Layout/SiteContent/SellOnResell")
);
const AccountSettingType = React.lazy(
  () =>
    import("components/Layout/SiteContent/AccountSettings/accountSettingType")
);
const OnBoarding = React.lazy(
  () => import("components/DirectSellers/onBoarding")
);
const SearchResults = React.lazy(
  () =>
    import("components/Layout/SiteContent/ExploreProductsPage/searchResults")
);
const Addresses = React.lazy(
  () => import("components/Layout/SiteContent/Addresses")
);
const CreateAddress = React.lazy(
  () => import("components/Layout/SiteContent/Addresses/createAddress")
);
const EditSavedAddress = React.lazy(
  () => import("components/Layout/SiteContent/Addresses/editAddress")
);
const VerifyEmailOTPForm = React.lazy(
  () => import("components/Layout/SiteContent/Signup/buyer/verifyEmailOTP")
);
const PaymentMethods = React.lazy(
  () => import("components/Layout/SiteContent/Payment/PaymentMethods")
);
const MySubscriptions = React.lazy(
  () => import("components/Layout/SiteContent/MySubscriptions")
);
const PrivacyPolicy = React.lazy(
  () => import("components/Layout/SiteFooter/PrivacyPolicy")
);
const CookiePolicy = React.lazy(
  () => import("components/Layout/SiteFooter/CookiePolicy")
);
const ShoppingCart = React.lazy(
  () => import("components/Layout/SiteContent/ShoppingCart")
);
const WishList = React.lazy(
  () => import("components/Layout/SiteContent/WishList")
);
const Notification = React.lazy(
  () => import("components/Layout/SiteContent/Notification")
);
const ThankYou = React.lazy(
  () => import("components/Layout/SiteContent/ThankYou")
);
const HazardPage = React.lazy(
  () => import("components/Layout/SiteContent/Dashboard/Postage/hazardPage")
);
const ShippingLabel = React.lazy(
  () => import("components/Layout/SiteContent/Dashboard/Postage/shippingLabel")
);

const OfferPage = React.lazy(
  () => import("components/Layout/SiteContent/Offers/offer-panel")
);

const routes: route[] = [
  {
    path: ROUTE_PATHS.EDIT_PRODUCT,
    component: (
      <LazyLoadWrapper>
        <EditProduct />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.BUYER_REGISTER,
    component: (
      <LazyLoadWrapper>
        <BuyerSignUp />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SELLER_REGISTER,
    component: (
      <LazyLoadWrapper>
        <SellerSignUp />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SIGN_IN,
    component: (
      <LazyLoadWrapper>
        <SignIn />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.GUEST_LOGIN,
    component: (
      <LazyLoadWrapper>
        <GuestLogin />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.DASHBOARD,
    component: (
      <LazyLoadWrapper>
        <Dashboard />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SHOPS,
    component: <ShopResults />,
    exact: true,
  },
  {
    path: ROUTE_PATHS.SHOP,
    component: <ShopListProduct />,
    exact: true,
  },
  {
    path: ROUTE_PATHS.SHIPPING_LABEL,
    component: (
      <LazyLoadWrapper>
        <ShippingLabel />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.HAZARD_PAGE,
    component: (
      <LazyLoadWrapper>
        <HazardPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.LISTING,
    component: (
      <LazyLoadWrapper>
        <Listing />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RESEND_EMAIL,
    component: (
      <LazyLoadWrapper>
        <ResendEmail />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.VERIFY_TOKEN,
    component: (
      <LazyLoadWrapper>
        <VerifyEmail />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD_OTP,
    component: (
      <LazyLoadWrapper>
        <ResetPasswordForm />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD_EMAIL,
    component: (
      <LazyLoadWrapper>
        <ResetPasswordForm />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    component: (
      <LazyLoadWrapper>
        <ForgotPassword />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PRODUCT_CATEGORY_ITEM,
    component: (
      <LazyLoadWrapper>
        <ProductPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.BLOG,
    component: (
      <LazyLoadWrapper>
        <Blog />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.CART,
    component: (
      <LazyLoadWrapper>
        <ShoppingCart />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PRODUCT_PAGE,
    component: (
      <LazyLoadWrapper>
        <ProductPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.WISHLIST,
    component: (
      <LazyLoadWrapper>
        <WishList />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.NOTIFICATION,
    component: (
      <LazyLoadWrapper>
        <Notification />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PAYMENT_PROCESS,
    component: (
      <LazyLoadWrapper>
        <PaymentProcess />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.GIFTCARD,
    component: (
      <LazyLoadWrapper>
        <GiftCard />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.AFFILIATE_LOGIN,
    component: (
      <LazyLoadWrapper>
        <AffSignIn />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.AFFILIATE_REGISTER,
    component: (
      <LazyLoadWrapper>
        <AffSignUp />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.CONTACT_US,
    component: (
      <LazyLoadWrapper>
        <ContactUs />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ABOUT_US,
    component: (
      <LazyLoadWrapper>
        <AboutUs />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SELL_ON_RESELL,
    component: (
      <LazyLoadWrapper>
        <SellOnResell />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SELLING_SITEMAP,
    component: (
      <LazyLoadWrapper>
        <SellingSitemap />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.TERMS,
    component: (
      <LazyLoadWrapper>
        <Terms />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.VERIFY_EMAIL_OTP,
    component: (
      <LazyLoadWrapper>
        <VerifyEmailOTPForm />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.AFFILIATE_HOME,
    component: (
      <LazyLoadWrapper>
        <AffiliateHome />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SPECIAL_OFFER,
    component: (
      <LazyLoadWrapper>
        <Home />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.EXCLUSIVE_COLLECTION,
    component: (
      <LazyLoadWrapper>
        <Home />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.BEST_OFFERS,
    component: (
      <LazyLoadWrapper>
        <Home />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PRODUCT_PAGE,
    component: (
      <LazyLoadWrapper>
        <ProductPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PRODUCT_OFFER,
    component: (
      <LazyLoadWrapper>
        <OfferPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.CATEGORIES,
    component: (
      <LazyLoadWrapper>
        <ProductCategories />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.EXPLORE_PRODUCTS,
    component: (
      <LazyLoadWrapper>
        <ExploreProductsPage />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.STRIPE_CHECKOUT,
    component: (
      <LazyLoadWrapper>
        <PaymentSection />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PAYMENT_STATUS,
    component: (
      <LazyLoadWrapper>
        <PaymentStatus />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.STRIPE_PAYMENT_STATUS,
    component: (
      <LazyLoadWrapper>
        <StripePaymentStatus />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PAYPAL_CHECKOUT,
    component: (
      <LazyLoadWrapper>
        <PayPalCheckout />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.TRACK_ORDER,
    component: (
      <LazyLoadWrapper>
        <OrderTracking />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.EDIT_ADDRESS,
    component: (
      <LazyLoadWrapper>
        <EditAddress />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ACCOUNT_SETTINGS,
    component: (
      <LazyLoadWrapper>
        <AccountSettings />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.RATING_REVIEW,
    component: (
      <LazyLoadWrapper>
        <RatingReview />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.MY_ORDERS,
    component: (
      <LazyLoadWrapper>
        <MyOrders />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PAYMENT_METHODS,
    component: (
      <LazyLoadWrapper>
        <PaymentMethods />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.MY_SUBSCRIPTION,
    component: (
      <LazyLoadWrapper>
        <MySubscriptions />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ADDRESSES,
    component: (
      <LazyLoadWrapper>
        <Addresses />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.CREATE_ADDRESS,
    component: (
      <LazyLoadWrapper>
        <CreateAddress />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ADDRESS_EDIT,
    component: (
      <LazyLoadWrapper>
        <EditSavedAddress />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.ACCOUNT_SETTING_TYPE,
    component: (
      <LazyLoadWrapper>
        <AccountSettingType />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.DIRECT_SELLERS_ONBOARDING,
    component: (
      <LazyLoadWrapper>
        <OnBoarding />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SEARCH_RESULTS,
    component: (
      <LazyLoadWrapper>
        <SearchResults />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PRIVACY_POLICY,
    component: (
      <LazyLoadWrapper>
        <PrivacyPolicy />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.PROHIBITED_ITEMS,
    component: (
      <LazyLoadWrapper>
        <ProhibitedItems/>
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.SUSTAINABILITY,
    component: (
      <LazyLoadWrapper>
        <Sustainability/>
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.COOKIE_POLICY,
    component: (
      <LazyLoadWrapper>
        <CookiePolicy />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.THANKYOU,
    component: (
      <LazyLoadWrapper>
        <ThankYou />
      </LazyLoadWrapper>
    ),
    exact: true,
  },
  {
    path: ROUTE_PATHS.HOME,
    component: (
      <LazyLoadWrapper>
        <Home />
      </LazyLoadWrapper>
    ),
    exact: false,
  },
];

export default routes;
