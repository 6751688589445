import snack from "components/wrapper/snack";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  createReviewStart,
  createReviewSuccess,
  createReviewFailure,
  getReviews
} from 'redux/rating';
import { createReview, getAllReview } from "redux/services/rating";

function* ratingSagaWatcher() {
  yield takeLatest(
    [createReviewStart.type,
    getReviews.type],
    ratingWorker,
  );
}

function* ratingWorker(action: any): any {
  try {
    switch (action.type) {
      case createReviewStart.type: {
        const { history } = action.payload;
        if (localStorage.getItem('access-token')) {
          const response: any = yield call(createReview, action.payload);
          yield put(createReviewSuccess({ reviews: response.ResponseBody }));
          snack.success ('Product review created');
          history.goBack();
        }
      }
        break;

      // eslint-disable-next-line no-lone-blocks
      case getReviews.type: {
        if (localStorage.getItem('access-token')) {
          const response: any = yield call(getAllReview, action.payload);
          yield put(createReviewSuccess({ reviews: response.ResponseBody }));
        }
      }
        break;


      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === createReviewStart.type) {
      yield put(createReviewFailure);
    }
  }
}

export default ratingSagaWatcher;
