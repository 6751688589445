import axios from "api/axiosController";

export const uploadDocs = async (data: any) => {
    let uri = "/user/image-upload";
    const formData = new FormData();
    for (const key of Object.keys(data.documents)) {
        formData.append('files', data.documents[key])
    }
  
    formData.append('email', data.email);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
  
    try {
      const res = await axios.post(uri, formData, { headers: { "Content-type": "multipart/form-data" } });
      return res.data; 
    } catch (e) {
      throw e;
    }
  };
  