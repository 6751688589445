import { Route, Switch } from "react-router-dom";
import Footer from "components/Layout/SiteFooter";
import routes from "./routeList";
import Header from "components/Layout/Header";
// import Search from "components/Layout/Header/search";
import {
  FieldDictionary,
  SearchProvider,
  Pipeline,
  FilterBuilder,
} from "@sajari/react-search-ui";

const CustomRoutes: React.FC = () => {
  const fields = new FieldDictionary({
    title: "name",
  });
  const pipeline = new Pipeline(
    {
      account: "1640286447936078869",
      collection: "resell",
    },
    "website"
  );

  const colorFilter = new FilterBuilder({
    name: "color",
    field: "color",
    count: true,
    array: true,
  });

  const categoryFilter = new FilterBuilder({
    name: "category",
    field: "category",
  });

  const brandFilter = new FilterBuilder({
    name: "brand",
    field: "brand",
  });

  const priceFilter = new FilterBuilder({
    name: "price",
    options: {
      High: "price > 5",
      Mid: "price > 2",
      Low: "price => 1",
    },

    // initial: ["High"],
  });

  const sizeFilter = new FilterBuilder({
    name: "size",
    options: {
      NoSize: "size== No Size",
      S: "size == S",
      M: "size == M",
      L: "size == L",
      XL: "size == XL",
      XXL: "size == XXL",
    },

    initial: ["High"],
  });

  return (
    <SearchProvider
      search={{
        pipeline,
        fields,
        filters: [
          colorFilter,
          categoryFilter,
          brandFilter,
          priceFilter,
          sizeFilter,
        ],
      }}
      searchOnLoad
    >
      <Header />
      <Switch>
        {routes.map(({ path, exact, ...rest }) => {
          return (
            <Route
              path={path}
              key={path}
              exact={exact}
              render={() => {
                return (
                  <>
                    {/* {path === "/" ? <Search /> : null} */}
                    {rest.component}
                    {path === "/" ? <Footer /> : null}
                  </>
                );
              }}
            />
          );
        })}
      </Switch>
    </SearchProvider>
  );
};

export default CustomRoutes;
