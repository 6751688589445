import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

const getNotificationData = async (userId: string) => {
  let uri = `/notification/${userId}`;
  try {
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e){
    throw e;
  }
};

const markReadNotification = async (notificationId: string) => {
  let uri = `/notification/${notificationId}/read`;
  try {
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e){
    throw e;
  }
};

export {
  getNotificationData,
  markReadNotification
}
