import { Box, Grid } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";
import ShopTileLayout from "./Shop/shopTile";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useEffect } from "react";

export default function ShopList(props: any) {
  const history = useHistory();
  const handleClick = (item: any) => {
    history.push({
      pathname: `/shops/${item._id}`,
      state: {
        item: item,
      },
    });
  };

  return (
    <div id="shopContainer">
      <Box sx={{ maxWidth: "1600px", margin: "0 auto" }}>
        <Grid container spacing={2} justifyContent="flex-start">
          {props.data.map((store: any) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              // className={classes.item}
              sx={{ transition: "box-shadow" }}
            >
              {" "}
              <ShopTileLayout shop={store} handleClick={handleClick} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
