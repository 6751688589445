import jwt from "jwt-decode";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  addToCartFailure,
  addToCartStart,
  addToCartSuccess,
  getCartItemsStart,
  removeFromCartStart,
  saveForLater,
  updateCartItem,
} from "redux/cart";
import {
  getCartItems,
  removeCartItems,
  updateCartItems,
} from "redux/services/cart";
import { removeFromWishListStart } from "redux/wishlist";
import { getAccessToken } from "utils/auth";

function* cartSagaWatcher() {
  yield takeLatest(
    [
      addToCartStart.type,
      getCartItemsStart.type,
      saveForLater.type,
      removeFromCartStart.type,
    ],
    cartWorker
  );
}

function* cartWorker(action: any): any {
  try {
    switch (action.type) {
      case addToCartStart.type: {
        //When access token is present in LS
        if (getAccessToken()) {
          const response: any = yield call(
            updateCartItems,
            action.payload._product
          );
          yield put(updateCartItem(response));
          if (action.payload._product.wishlistId) {
            yield put(
              removeFromWishListStart(action.payload._product.wishlistId)
            );
          }

           yield put(getCartItemsStart());
        } else {
          //When access token is not present in LS
          let cartBanner: number = 0,
            cartItems: any = [],
            saveCartItems: any = [];

          if (localStorage.getItem("cartItems")) {
            cartItems = Object(
              JSON.parse(localStorage.getItem("cartItems") || "{}")
            );
            saveCartItems = Object(
              JSON.parse(localStorage.getItem("saveCartItem") || "{}")
            );
            cartBanner = JSON.parse(localStorage.getItem("cartBanner") || "{}");

            if (action.payload._product.saveForLater === true) {
              for (let i = 0; i < saveCartItems.length; i++) {
                if (
                  saveCartItems[i].productId ===
                  action.payload._product.productId
                ) {
                  saveCartItems[i] = action.payload._product;
                  cartItems[i]["saveForLater"] = true;
                  cartItems[i]["cartQty"] = saveCartItems[i].quantity;
                }
              }
            } else {
              for (let i = 0; i < saveCartItems.length; i++) {
                if (
                  saveCartItems[i].productId ===
                  action.payload._product.productId
                ) {
                  cartBanner -=
                    saveCartItems[i].quantity <= cartBanner
                      ? saveCartItems[i].quantity
                      : 0;
                  saveCartItems[i] = action.payload._product;
                  cartItems[i]["saveForLater"] = false;
                  cartItems[i]["cartQty"] = saveCartItems[i].quantity;
                  break;
                }
              }
              for (let i = 0; i < saveCartItems.length; i++) {
                cartItems[i]["cartQty"] = saveCartItems[i].quantity;
              }
              console.log("saveCartItems ", saveCartItems)
              if (
                !saveCartItems.find(
                  (item: any) =>
                    item.productId === action.payload._product.productId
                )
              ) {
                saveCartItems.push(action.payload._product);
                cartItems.push(action.payload.product);
              }
            }
            localStorage.setItem(
              "cartItems",
              Object(JSON.stringify(cartItems))
            );
            localStorage.setItem(
              "saveCartItem",
              Object(JSON.stringify(saveCartItems))
            );
            localStorage.setItem(
              "cartBanner",
              Object(JSON.stringify(cartBanner))
            );
          } else {
            cartItems.push(action.payload.product);
            saveCartItems.push(action.payload._product);
            cartBanner += action.payload._product.quantity;

            let cartDataCopy = [];

            for (let i = 0; i < saveCartItems.length; i++) {
              cartDataCopy = cartItems.map((item: any) =>
                Object.assign({}, item, { cartQty: saveCartItems[i].quantity })
              );
              // cartItems[i]['cartQty'] = saveCartItems[i].quantity
            }

            localStorage.setItem(
              "saveCartItem",
              Object(JSON.stringify(saveCartItems))
            );
            localStorage.setItem(
              "cartBanner",
              Object(JSON.stringify(cartBanner))
            );
            localStorage.setItem(
              "cartItems",
              Object(JSON.stringify(cartDataCopy))
            );
          }
          yield put(getCartItemsStart());
        }
        break;
      }

      case removeFromCartStart.type: {
        if (getAccessToken()) {
          yield call(removeCartItems, action.payload);
          yield put(getCartItemsStart());
        } else {
          let cartItems: any = [],
            saveCartItems: any = [],
            cartBanner: any = [];

          if (localStorage.getItem("cartItems")) {
            //Get items from local storage
            cartItems = Object(
              JSON.parse(localStorage.getItem("cartItems") || "{}")
            );
            saveCartItems = Object(
              JSON.parse(localStorage.getItem("saveCartItem") || "{}")
            );
            cartBanner = JSON.parse(localStorage.getItem("cartBanner") || "{}");

            //Delete the item from respective arrays
            saveCartItems = saveCartItems.filter(
              (item: any) => item.productId !== action.payload
            );
            cartItems = cartItems.filter(
              (item: any) => item._id !== action.payload
            );

            //Update banner
            for (let i = 0; i < saveCartItems.length; i++) {
              cartBanner -= saveCartItems[i].quantity;
            }

            //Set Local Storage keys
            localStorage.setItem(
              "saveCartItem",
              Object(JSON.stringify(saveCartItems))
            );
            localStorage.setItem(
              "cartBanner",
              Object(JSON.stringify(cartBanner))
            );
            localStorage.setItem(
              "cartItems",
              Object(JSON.stringify(cartItems))
            );
          }

          yield put(getCartItemsStart());
        }
        break;
      }

      case getCartItemsStart.type: {
        if (getAccessToken()) {
          const user: any = jwt(JSON.stringify(getAccessToken()));
          // Save cart items from local storage
          let saveCartItems = Object(
            JSON.parse(localStorage.getItem("saveCartItem") || "{}")
          );

          if (JSON.stringify(localStorage.getItem("saveCartItem")) !== "null") {
            for (let i = 0; i < saveCartItems.length; i++) {
              saveCartItems[i].userId = user._id;
              const response: any = yield call(
                updateCartItems,
                saveCartItems[i]
              );
            }

            localStorage.removeItem("cartItems");
            localStorage.removeItem("saveCartItem");
            localStorage.removeItem("cartBanner");
          }

          const response: any = yield call(getCartItems);
          yield put(addToCartSuccess({ cartItems: response.ResponseBody }));
        } else {
          if (JSON.stringify(localStorage.getItem("cartItems")) !== "null") {
            yield put(
              addToCartSuccess({
                cartItems: Object(
                  JSON.parse(localStorage.getItem("cartItems") || "{}")
                ),
                saveCartItem: Object(
                  JSON.parse(localStorage.getItem("saveCartItem") || "{}")
                ),
                cartBanner: JSON.parse(
                  localStorage.getItem("cartBanner") || "{}"
                ),
              })
            );
          }
          else{
            yield put (addToCartSuccess(
              {
              cartItems: [],
              saveCartItem: [],
              cartBanner: 0
              }
            )
          )
          }
        }
        break;
      }

      case saveForLater.type: {
        if (getAccessToken()) {
          if (action.payload) {
          // Save cart items from local storage
              let saveCartItems:any = Object(
                JSON.parse(localStorage.getItem("saveCartItem") || "{}")
              );

            }
          const response: any = yield call(
            updateCartItems,
            action.payload._product
          );
          yield call(updateCartItems, action.payload._product);
          yield put(getCartItemsStart());

        } else {
          //When access token is not present in LS
          let cartBanner: number = 0,
            cartItems: any = [],
            saveCartItems: any = [];

          if (localStorage.getItem("cartItems")) {
            cartItems = Object(
              JSON.parse(localStorage.getItem("cartItems") || "{}")
            );
            saveCartItems = Object(
              JSON.parse(localStorage.getItem("saveCartItem") || "{}")
            );
            cartBanner = JSON.parse(localStorage.getItem("cartBanner") || "{}");

            if (action.payload.id) {
              for (let i = 0; i < saveCartItems.length; i++) {
                if (
                  saveCartItems[i].productId ===
                  action.payload
                ) {
                  console.log("action.payload.idaction.payload.idaction.payload.idaction.payload.id",action.payload.id);

                }
              }
            } else {
              for (let i = 0; i < saveCartItems.length; i++) {
                if (
                  saveCartItems[i].productId ===
                  action.payload._product.productId
                ) {
                  cartBanner -=
                    saveCartItems[i].quantity <= cartBanner
                      ? saveCartItems[i].quantity
                      : 0;
                  saveCartItems[i] = action.payload._product;
                  cartItems[i]["saveForLater"] = false;
                  cartItems[i]["cartQty"] = saveCartItems[i].quantity;
                  break;
                }
              }

              for (let i = 0; i < saveCartItems.length; i++) {
                cartItems[i]["cartQty"] = saveCartItems[i].quantity;
              }

              if (
                !saveCartItems.find(
                  (item: any) =>
                    item.productId === action.payload._product.productId
                )
              ) {
                saveCartItems.push(action.payload._product);
                cartItems.push(action.payload.product);
              }
            }
            localStorage.setItem(
              "cartItems",
              Object(JSON.stringify(cartItems))
            );
            localStorage.setItem(
              "saveCartItem",
              Object(JSON.stringify(saveCartItems))
            );
            localStorage.setItem(
              "cartBanner",
              Object(JSON.stringify(cartBanner))
            );
          } else {
            cartItems.push(action.payload.product);
            saveCartItems.push(action.payload._product);
            cartBanner += action.payload._product.quantity;

            let cartDataCopy = [];

            for (let i = 0; i < saveCartItems.length; i++) {
              cartDataCopy = cartItems.map((item: any) =>
                Object.assign({}, item, { cartQty: saveCartItems[i].quantity })
              );
              // cartItems[i]['cartQty'] = saveCartItems[i].quantity
            }

            localStorage.setItem(
              "saveCartItem",
              Object(JSON.stringify(saveCartItems))
            );
            localStorage.setItem(
              "cartBanner",
              Object(JSON.stringify(cartBanner))
            );
            localStorage.setItem(
              "cartItems",
              Object(JSON.stringify(cartDataCopy))
            );
          }
          yield put(getCartItemsStart());
        }
        break;
      }

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === addToCartStart.type) {
      if (err.response.data.message === "Invalid Token") {
        yield put(addToCartFailure());
      }
    }
    if (action.type === getCartItemsStart.type) {
      if (err.response.data.message === "Invalid Token") {
        yield put(addToCartFailure());
      }
    }
  }
}

export default cartSagaWatcher;
