import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "resell-web.firebaseapp.com",
  projectId: "resell-web",
  storageBucket: "resell-web.appspot.com",
  messagingSenderId: "1090191155712",
  appId: "1:1090191155712:web:5fbee4930ece4bc3652ee5",
  measurementId: "G-KJLNDE30M0"
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
