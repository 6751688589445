import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  shippingLabel : any;
  orders:any
}

const initialState: State = {
  isLoading: false,
  error : false,
  shippingLabel : [],
  orders:{}
};

export const shippingSlice = createSlice({
  name: 'shipping',
  initialState,
  reducers: {
    printShippingLabelStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    printShippingLabelSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      orders : action.payload
    }),
    printShippingLabelFailure: (state: State) => ({
      ...initialState,
      error: true,
      isLoading: false,
    }),
  }
});

export const {
  printShippingLabelStart,
  printShippingLabelSuccess,
  printShippingLabelFailure,
} = shippingSlice.actions;
export default shippingSlice.reducer;
