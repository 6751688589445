import { createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "utils/auth";

interface State {
  isLoading: boolean;
  error: boolean;
  documents: [];
  accessToken?: string | null;
}

const initialState: State = {
  isLoading: true,
  error: false,
  documents: [],
  accessToken: getAccessToken() || null,
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    
    uploadDocumentsStart: (state: State, action) => {
      return({
      ...initialState,
      isLoading: true,
    })},
    uploadDocumentsSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      documents: action.payload.documents,
    }),
    uploadDocumentsFailure: (state: State, action) => ({
      ...state,
      isLoading: false,
      error: true,
      msg: action.payload.msg,
    }),
   
  },
});

export const {
  uploadDocumentsFailure,
  uploadDocumentsStart,
  uploadDocumentsSuccess,
} = uploadSlice.actions;
export default uploadSlice.reducer;
