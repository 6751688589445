import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const updateWishListItems = async (data:any) => {
  let uri = '/userItems';

  try {
    
    const res = await axios.post(uri, data,{ headers: {"Authorization" : `Bearer ${getAccessToken()}`} });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const removeFromWishList = async (wishlistId:any) => {
  let uri = `/userItems/${wishlistId}`;

    try {
      const res = await axios.delete (uri, { headers: {"Authorization" : `Bearer ${getAccessToken()}`} });
      return res.data;
    } catch (e) {
      throw e;
    }
};


export const getWishListItems = async () => {
  let uri = `/userItems/wishlist`;

    try {
      const res = await axios.get(uri, { headers: {"Authorization" : `Bearer ${getAccessToken()}`}});
      return res.data;
    } catch (e) {
      throw e;
    }
};
