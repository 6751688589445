import snack from "components/wrapper/snack";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getNotification,
  getNotificationSuccess,
  getNotificationFailure,
  markReadNotificationStart,
  markReadNotificationFailure,
  markReadNotificationSuccess
} from "redux/notification";

import {
  getNotificationData,
  markReadNotification
} from "redux/services/notification";
import { getAccessToken } from "utils/auth";

function* NotificationSagaWatcher() {
  yield takeLatest(
    [
      getNotification.type,
      markReadNotificationStart.type
    ],
    notificationWorker
  );
}

function* notificationWorker(action: any): any {
  try {
    if (getAccessToken()) {
      switch (action.type) {
        // eslint-disable-next-line no-lone-blocks
        case getNotification.type:
          {   const response: any = yield call(getNotificationData, action.payload.userId);
              yield put(getNotificationSuccess({ notifications: response.ResponseBody }));
          }
          break;
        case markReadNotificationStart.type:
            yield call(markReadNotification, action.payload.notificationId);
          break;
      }
    } else {
      console.log("Unauthorized");
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getNotification.type) {
      yield put(getNotificationFailure({ notifications: [] }));
    }
  }
}

export default NotificationSagaWatcher;
