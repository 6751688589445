import Avatar from "@mui/material/Avatar";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box } from "@mui/system";
import "./style.css";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Tooltip from '@mui/material/Tooltip';

const SingleProductLayout = ({
  item,
  product,
  handleClick,
  handleOpen,
  handleAddToCart,
  handleWL,
  handleEditIcon,
  isInWishlist,
  editIcon
}: any) => {

  let imageUrl = Array.isArray(product?.productgroupimageurl) ? product.productgroupimageurl[0] : product.productgroupimageurl;

  return (
    <div className="exclusive-product-details">
      <div className="exclusive-product-content">
        <div
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            borderRadius: "10px",
            minWidth: "250px",
          }}
          className={ Number(product.Quantity) <= 0 ? "disableCard" : "product-container"}
        >

          <div className="exclusive-product-image">
            <Box
              component="img"
              sx={{
                height: 250,
                width: "100%",
              }}
              alt={`${product?.title}`}
              src={imageUrl}
              onClick={() => handleClick(product)}
            />
            { Number(product?.Quantity) <= 0 ? <span className="soldOut">SOLD OUT</span>:""}
          </div>
          <div className="exclusive-product-name exclusive-product-card-padding">
            <p
              className="product--text product--text__theme_blue"
              onClick={() => handleClick(product)}
            >
              {product?.title}
            </p>
          </div>
          <p className="exclusive-product-card-padding product--text product--text__bold product--salesprice__margin">
            ${product.saleprice}
          </p>
          <div className="exclusive-product-card-padding product--card__size__brand">
            <p className="product--text__theme_maroon product--text product---card__size__val">
              {product.size === "No Size" ? "" : "Size: " + product.size}
            </p>
            {product.brand && <span className="product--card__divider">|</span>}
            <p className="product--text product--text__theme_maroon">
              {product.brand ? product.brand : ""}
            </p>
          </div>
          <div className="exclusive-product-card-padding product--card__username__av">
            <Avatar sx={{ width: 30, height: 30 }}></Avatar>
            <p className="product--text avatar__text">{product.userName}</p>
          </div>
          <hr className="product--text__theme_black" />
          {window.location.pathname === "/search-results" ? (
            <div
              className="exclusive-product-card-padding"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Tooltip title = "Add to Wishlist">
                {isInWishlist ? (
                  <FavoriteIcon
                    style={{ width: "16px", color: "#EF5051" }}
                    onClick={() =>
                      handleWL(product.id, product.title, product.saleprice)
                    }
                  />
                ) : (
                  <FavoriteBorderOutlinedIcon
                    style={{ width: "16px", color: "#EF5051" }}
                    onClick={() =>
                      handleWL(product.id, product.title, product.saleprice)
                    }
                    sx={{
                      opacity: 0.6,
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                )}
              </Tooltip>

              <Tooltip title = "Add to Cart">
                <ShoppingCartIcon
                  style={{ width: "16px", color: "#EF5051" }}
                  onClick={() =>
                    {console.log(" here 2")
                    return handleAddToCart(product)}
                  }
                  sx={{
                    opacity: 0.6,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Tooltip>

              <Tooltip title = "Share this product">
                <ShareIcon
                  style={{ width: "16px", color: "#EF5051" }}
                  onClick={() =>
                    handleOpen({ id: product.id, title: product.title })
                  }
                  sx={{
                    opacity: 0.6,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Tooltip>
            </div>
          )
          : (

            <div className="exclusive-product-cart--footer__seller">
              {!editIcon && <Tooltip title = "Add to Wishlist">
                {isInWishlist ? (
                  <FavoriteIcon
                    style={{ width: "16px", color: "#EF5051" }}
                    onClick={() =>
                      handleWL(product._id, product.title, product.saleprice)
                    }
                  />
                ) : (
                  <FavoriteBorderOutlinedIcon
                    style={{ width: "16px", color: "#EF5051" }}
                    onClick={() =>{
                      handleWL(product._id, product.title, product.saleprice)
                    }

                    }
                    sx={{
                      opacity: 0.6,
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                )}
              </Tooltip>
              }

              {!editIcon &&<Tooltip title = "Add to Cart">
                <ShoppingCartIcon
                  style={{ width: "16px", color: "#EF5051" }}
                  onClick={() =>
                    {console.log(" here 3 ")
                    return handleAddToCart(product)}
                  }
                  sx={{
                    opacity: 0.6,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Tooltip>
              }

              {editIcon && <Tooltip title = "Edit this product">
              <EditTwoToneIcon
                className="exclusive-product-cart--footer__editIcon"
                onClick = {() => handleEditIcon(product)}
                sx={{
                  opacity: 0.6,
                  "&:hover": {
                    opacity: 1,
                  },
                }}/>
              </Tooltip>}

              <Tooltip title = "Share this product">
                <ShareIcon
                  style={{ width: "16px", color: "#EF5051" }}
                  onClick={() =>
                    handleOpen({ id: product._id, title: product.title })
                  }
                  sx={{
                    opacity: 0.6,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Tooltip>

            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleProductLayout;
