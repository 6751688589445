import { call, put, takeLatest } from "redux-saga/effects";
import snack from "components/wrapper/snack";
import {
  backgroundImageUploadStart,
  backgroundImageUploadSuccess,
  backgroundImageUploadFailure,
} from "redux/backgroundImageUpload"; 
import {
  uploadDocs,
} from "redux/services/upload";
import { accountSettingsUpdateStart } from "redux/accountSettings";


function* backgroundImageUploadSagaWatcher() {
  console.log('backgroundImageUploadStart',backgroundImageUploadStart.type)
  yield takeLatest(
    [
      backgroundImageUploadStart.type,
    ],
    backgroundImageUploadWorker
  );
}

function* backgroundImageUploadWorker(action: any): any {
  console.log('uploadDocumentsStart2',action.payload)

  try {
    switch (action.type) {
      case backgroundImageUploadStart.type:
        {
          const response: any = yield call(uploadDocs, action.payload);
          yield put(
            backgroundImageUploadSuccess({ documents: response.ResponseBody })
          );
          yield put(accountSettingsUpdateStart({
            bannerImageUrl : {bannerImages : response.ResponseBody[0].url},
          }))
          snack.success("Document uploaded successfully");
        }
        break;
      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  
    
    if (action.type === backgroundImageUploadStart.type) {
      snack.error(err?.response?.data?.msg);
      yield put(backgroundImageUploadFailure({ msg: err?.response?.data?.msg }));
    }

  }
}

export default backgroundImageUploadSagaWatcher;
