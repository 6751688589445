import axios from "api/axiosController";

export const createReview = async (data:any) => {
  let uri = `/product/review`;

    try {
      const res = await axios.post(uri, data, { headers: {"Authorization" : `Bearer ${localStorage.getItem('access-token')}`} });
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const getAllReview = async (userId:string) => {
  let uri = `/product/reviews`;

    try {
      const res = await axios.get(uri, { headers: {"Authorization" : `Bearer ${localStorage.getItem('access-token')}`} });
      return res.data;
    } catch (e) {
      throw e;
    }
};
