import { createSlice } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  error: boolean;
  notifications: any;
  isSuccess: boolean;
}

const initialState: State = {
  isLoading: true,
  error: false,
  notifications: [],
  isSuccess:false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNotification: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      isSuccess:false,
    }),
    getNotificationSuccess: (state: State, action) => ({
      ...initialState,
      isLoading: false,
      notifications: action.payload.notifications,
      isSuccess:true,
    }),
    getNotificationFailure: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      notifications: action.payload.notifications,
      isSuccess:false,
    }),
    markReadNotificationStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      isSuccess:false,
    }),
    markReadNotificationSuccess: (state: State, action) => ({
      ...initialState,
      isLoading: false,
      notifications: action.payload.notifications,
      isSuccess:true,
    }),
    markReadNotificationFailure: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      notifications: action.payload.notifications,
      isSuccess:false,
    })
  }
});

export const {
  getNotification,
  getNotificationSuccess,
  getNotificationFailure,
  markReadNotificationStart,
  markReadNotificationSuccess,
  markReadNotificationFailure
} = notificationSlice.actions;
export default notificationSlice.reducer;
