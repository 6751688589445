import axios from "api/axiosController";

export const getCategoryData = async () => {
  let uri = '/categories';
    try {
      const res = await axios.get(uri);
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const getTopCategories = async () => {
  let uri = '/categories/top';
  try {
    const res = await axios.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
}
