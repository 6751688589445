import { call, put, takeLatest } from "redux-saga/effects";
import {
  getOffersStart,
  getOffersSuccess,
  updateOffersStatusStart,
  updateOffersStatusSuccess,
} from "redux/offers";
import { getOffers, updateOfferStatus } from "redux/services/offers";
import Snack from "components/wrapper/snack";

function* offersSagaWatcher() {
  yield takeLatest(
    [getOffersStart.type, updateOffersStatusStart.type],
    offerWorker
  );
}

function* offerWorker(action: any): any {
  try {
    switch (action.type) {
      case getOffersStart.type:
        {
          const response: any = yield call(getOffers, action.payload);
          console.log("responseresponse", response);
          yield put(getOffersSuccess({ Offers: response.ResponseBody }));
        }
        break;
      case updateOffersStatusStart.type:
        {
          const response: any = yield call(updateOfferStatus, action.payload);
          console.log("responseresponse", response);
          if(response.Status === "Complete"){
            Snack.success("Offer Approved succsessfully");
            yield put(updateOffersStatusSuccess({ Offers: response.ResponseBody }));
          }else{
            Snack.success("Offer does not Approved");
          }
        }
        break;
      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  }
}

export default offersSagaWatcher;
