import {_axiosInstance} from "api/axiosController";

export const getHomePageProducts = async () => {
  let uri = `/api/v1/product/homePageProducts`;
  try {
    const res:any = await _axiosInstance.get(uri);
    
    return res.data;
  } catch (e) {
    throw e;
  }
};
