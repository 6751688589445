import React, { Suspense } from 'react'

interface ILazyLoadWrapper {
  children: React.ReactNode;
}

const LazyLoadFallback = () => <p>Loading...</p>

const LazyLoadWrapper = ({
  children
}: ILazyLoadWrapper) => {
  return (
    <Suspense fallback={<LazyLoadFallback/>}>
      {children}
    </Suspense>
  )
}

export default LazyLoadWrapper