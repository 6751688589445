import { RootState } from "redux/store";

export const cartItemsSelector = (state: RootState) => state.cart.cartItems

export const cartIsLoadingSelector = (state: RootState) => state.cart.isLoading

export const cartBannerSelector = (state: RootState) => state.cart.cartBanner

export const saveCartItemSelector = (state: RootState) => state.cart.saveCartItem

export const cartErrorSelector = (state: RootState) => state.cart.error

export const paymentIntentIdSelector= (state:RootState)=>state.payment.paymentIntentId;