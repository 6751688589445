import Modal from "@mui/material/Modal";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snack from "components/wrapper/snack";
import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { titelize } from "utils/titelize";
import "./style.css";
const ShareModal = ({
  handleClose,
  open,
  setOpen,
  id,
  title,
  facebookIcon,
  twitterIcon,
  whatsAppIcon,
  instagramIcon,
  shareLinkIcon,
}: any) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            borderRadius: "5px",
            boxShadow: "24",
            p: 4,
            height: 240,
            background: "white",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Share this listing
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "0.6rem",
              alignSelf: "stretch",
            }}
          >
            {facebookIcon && (
              <FacebookIcon
                sx={{
                  color: "#1565c0",
                  opacity: 0.6,
                  cursor: "pointer",
                  transition: "1s",
                  "&:hover": {
                    opacity: 1,
                    transform: "scale(1.05)",
                    transition: "1s",
                  },
                }}
              />
            )}
            {twitterIcon && (
              <TwitterIcon
                sx={{
                  color: "#42a5f5",
                  opacity: 0.6,
                  cursor: "pointer",
                  transition: "1s",
                  "&:hover": {
                    opacity: 1,
                    transform: "scale(1.05)",
                    transition: "1s",
                  },
                }}
              />
            )}
            {instagramIcon && (
              <InstagramIcon
                sx={{
                  color: "#9000EE",
                  opacity: 0.6,
                  cursor: "pointer",
                  transition: "1s",
                  "&:hover": {
                    opacity: 1,
                    transform: "scale(1.05)",
                    transition: "1s",
                  },
                }}
              />
            )}
            {whatsAppIcon && (
              <WhatsAppIcon
                sx={{
                  color: "#4caf50",
                  opacity: 0.6,
                  cursor: "pointer",
                  transition: "1s",
                  "&:hover": {
                    opacity: 1,
                    transform: "scale(1.05)",
                    transition: "1s",
                  },
                }}
              />
            )}
            {shareLinkIcon && (
              
              <CopyToClipboard
                text={`${window.location.origin}/product-page/${id}/${titelize(
                  title
                )}`}
                onCopy={() => {
                  setOpen(false);
                  Snack.success("Link copied to clipboard!");
                }}
              ><Tooltip title="Copy Below link">
                <InsertLinkIcon
                  sx={{
                    opacity: 0.6,
                    cursor: "pointer",
                    transition: "1s",
                    "&:hover": {
                      opacity: 1,
                      transform: "scale(1.05)",
                      transition: "1s",
                    },
                  }}
                />
                </Tooltip>
              </CopyToClipboard>
              
            )}
          </Box>
          <p className="product--link">{`${window.location.origin}/product-page/${id}/${titelize(
                  title
                )}`}</p>
        </Box>
      </Modal>
    </div>
  );
};

export default ShareModal;
