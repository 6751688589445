import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  reviews : any;
}

const initialState: State = {
  isLoading: true,
  error: false,
  reviews: []
};

export const reviewSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {
    createReviewStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    createReviewSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      reviews : action.payload.reviews
    }),
    createReviewFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getReviews: (state:State, action) => ({
      ...initialState,
      isLoading: true
    }),
  }
});

export const {
  createReviewStart,
  createReviewSuccess,
  createReviewFailure,
  getReviews
} = reviewSlice.actions;
export default reviewSlice.reducer;
