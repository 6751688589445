import { createSlice } from '@reduxjs/toolkit';


interface State {
    isLoading: boolean;
    error: boolean;
    msg: string;
    products: any
  }

const initialState: State = {
    isLoading: true,
    error : false,
    msg: '',
    products: []
  };
  
  export const homePageProductsSlice = createSlice({
    name: 'homePageProducts',
    initialState,
    reducers: {
      getProductsFeedStart: (state: State) => ({
        ...state,
        isLoading: true
      }),
      getProductFeedSuccess: (state: State, action) => ({
        ...state,
        products: action.payload.result
      }),
      getProductFeedFailure: (state: State) => ({
        ...state,
        error: true,
        isLoading: false,
      }),
    }
  });
  
  export const {
    getProductsFeedStart,
    getProductFeedSuccess,
    getProductFeedFailure
  } = homePageProductsSlice.actions;
  export default homePageProductsSlice.reducer;
  