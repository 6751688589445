import axios from "api/axiosController";
import { AxiosError } from "axios";
import Snack from "components/wrapper/snack";


export const getOffers = async (data: any) => {
  let uri = `product/get-offers/${data}`;
  try {
    const res = await axios.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const updateOfferStatus = async (data: any) => {
    let uri = `product/update-offers`;
    try {
      const res = await axios.post(uri,data);
      return res.data;
    } catch (e:any) {
      Snack.error(e.message);
      throw e;
    }
  };
  

