import * as React from "react";
import { Grid } from "@mui/material";
import UserListings from "../../Dashboard/UserListing/userListings";
import bannerImg from "assets2/images/plain-background-Large.jpeg";
import EditDescription from "../../AccountSettings/editDescription";
import EditShopModal from "./editShopModal";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import { useMediaQuery } from '@mui/material';
import { TabContext } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import ShareModal from "../../ExploreProductsPage/searchResults/shareModal";
import useProduct from "../../ExploreProductsPage/searchResults/useProduct";
import "./shop.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { PROFILE_PIC } from "utils/constants/constants";
import styled from "styled-components";

const TabButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 0px;
  margin-left: 2px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 550;
  background-color: #0066FF;
  color: white;
  border-radius: 6px;
  border-width: 0px;
  height: 48px;

  transition: transform 0.5s ease; 

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 450px) {
    transform: none;
  }
`;

const MarkdownComponent = styled(ReactMarkdown)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const UserShop = (props: any) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [follow, setFollow] = React.useState("FOLLOW");

  const { handleClose, setOpen, handleOpen, modalProps, open } = useProduct();

  const isSmallScreen = useMediaQuery('(max-width:470px)');

  return (
    <div id="UserShopContainer">
      <div>
        <img
          width={3840}
          height={627}
          className="bannerImg"
          src={
            props?.user?.bannerImages && props?.user?.bannerImages.length > 0
              ? props.user?.bannerImages[0]
              : bannerImg
          }
          alt="Banner Image"
        />

        <div
          id="edit-button"
          style={{
            float: "right",
            position: "relative",
            bottom: "263px",
            zIndex: 5,
          }}
        >
          {props.isUser ? <EditShopModal /> : null}
        </div>
        <div
          id="edit-button"
          style={{ position: "relative", bottom: "270px", left: "40px" }}
        >
          <div>
            <img
              className="profilePic"
              src={
                props?.user && props?.user?.profileImage
                  ? props.user.profileImage
                  : PROFILE_PIC
              }
              alt="Profile Picture"
            />
          </div>
        </div>
      </div>
      <div id="shop-name">
        <h6 className="ellipseCss">{props?.user?.username}</h6>
        <h6 className="ellipseCss">
          {props?.user?.nickName && props?.user?.nickName}
        </h6>
        <h6 className="ellipseCss">
          {props?.user?.city && props?.user?.city}
          {props?.user?.state && ", " + props?.user?.state}
        </h6>
      </div>
      <div style={{ marginTop: "-150px" }}>
        <Box>
          <TabContext value={value}>
            <Box
              id="Shop-Tabs"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList onChange={handleChange} aria-label="dashboard-tabs">
              <Tab label="Listings" value="1" className="tab"
              sx={{
                "&.tab": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "0px",
                  marginLeft: "2px",
                  marginRight: "4px",
                  marginBottom: "0",
                  padding: "6px 12px",
                  cursor: "pointer",
                  fontSize: "12px",
                  backgroundColor: "#0066FF",
                  color: "white",
                  borderRadius: "6px",
                  transition: "transform 0.5s ease",
                  "@media (min-width: 450px)": {
                    "&:hover": {
                      transform: "scale(1.02)",
                    },
                  },
                },
                }}
                />
              <Tab label="About" value="2" className="tab"
              sx={{
                 "&.tab": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "0px",
                marginLeft: "2px",
                marginRight: "4px",
                marginBottom: "0",
                padding: "6px 12px",
                cursor: "pointer",
                fontSize: "12px",
                backgroundColor: "#0066FF",
                color: "white",
                borderRadius: "4px",
                height: "24px",
                transition: "transform 0.5s ease",
                "@media (min-width: 450px)": {
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                },
                },
                }}
                />
                <TabButton value="3" className="tab" onClick={() => {setFollow(follow === "FOLLOWING" ? "UNFOLLOWED" : "FOLLOWING")}}> {follow} </TabButton>
              </TabList>
            </Box>

            <TabPanel value="1">
              <UserListings
                userId={props?.user?._id}
                editIcon={props.editIcon}
                handleOpen={handleOpen}
              />
            </TabPanel>

            <TabPanel value="2">
            {props.isUser? <EditDescription/> : null }
            <MarkdownComponent className="shop-description">{props?.user?.description}</MarkdownComponent>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      <ShareModal
        handleClose={handleClose}
        setOpen={setOpen}
        open={open}
        id={modalProps.id}
        title={modalProps.title}
        shareLinkIcon
      />
    </div>
  );
};
export default UserShop;
