import { createSlice } from "@reduxjs/toolkit";

interface State {
    tabValue:string
}

const initialState :State = {
    tabValue:"1"
}
export const tabsSlice = createSlice({
    name:"tabs",
    initialState,
    reducers:{
        updateTabValue:(state:State,action) => ({
            ...initialState,
            tabValue:action.payload
        })
    }
    }

)


export const {
updateTabValue
} = tabsSlice.actions;

export default tabsSlice.reducer;

