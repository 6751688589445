import { IproductCategories } from "../types/productCategories";

import {
  ANIMALS_AND_PET_SUPPLIES,
  ANTI_PERSPIRANT,
  APPAREL_AND_ACCESSORIES,
  ART_AND_ENTERTAINMENT,
  BABY_AND_TODDLER,
  BAGS,
  BUSSINESS_AND_INDUSTRIAL,
  CAP,
  CEREMONIAL,
  DEVICE,
  EBOOK_READER_CASES,
  ELECTRONICS,
  EREADER,
  FOOD_BEVERAGES,
  FURNITURES,
  GAMING,
  GREEN_HOUSE,
  HARDWARE,
  HEALTH_AND_BEAUTY,
  JEWELERY,
  KITCHEN,
  MATURE,
  MOBILE_PREPAID_CARDS,
  OFFICE_SUPPLIES,
  SOFTWARE,
  SPORTING_GOODS,
  SUNGLASSES,
  TOOLS,
  TOYS,
  VEHICLES_AND_PARTS,
  VIDEO_CONSOLE,
} from "assets2/images";

const productCategories: IproductCategories[] = [
  {
    id: 0,
    category: "Home & Kitchen",
    categoryImage: GREEN_HOUSE,
  },
  {
    id: 1,
    category: "Hardware",
    categoryImage: TOOLS,
  },
  {
    id: 2,
    category: "Video Games",
    categoryImage: VIDEO_CONSOLE,
  },
  {
    id: 3,
    category: "Mobile & Tablets",
    categoryImage: DEVICE,
  },
  {
    id: 4,
    category: "Electronics",
    categoryImage: ELECTRONICS,
  },
  {
    id: 5,
    category: "Computers",
    categoryImage: GAMING,
  },
  {
    id: 6,
    category: "Kitchen",
    categoryImage: KITCHEN,
  },
  {
    id: 7,
    category: "Sun Glasses",
    categoryImage: SUNGLASSES,
  },
  {
    id: 8,
    category: "Toys",
    categoryImage: TOYS,
  },
  {
    id: 9,
    category: "Jewelery",
    categoryImage: JEWELERY,
  },
  {
    id: 10,
    category: "Hats",
    categoryImage: CAP,
  },
  {
    id: 11,
    category: "HOME & KITCHEN",
    categoryImage: GREEN_HOUSE,
  },
  {
    id: 12,
    category: "HARDWARE",
    categoryImage: TOOLS,
  },
  {
    id: 13,
    category: "VIDEO GAMES",
    categoryImage: VIDEO_CONSOLE,
  },
  {
    id: 14,
    category: "Mobile & Tablets",
    categoryImage: DEVICE,
  },
  {
    id: 15,
    category: "Electronics",
    categoryImage: ELECTRONICS,
  },
  {
    id: 16,
    category: "Computers",
    categoryImage: GAMING,
  },
  {
    id: 17,
    category: "Kitchen",
    categoryImage: KITCHEN,
  },
  {
    id: 18,
    category: "Sun Glasses",
    categoryImage: SUNGLASSES,
  },
  {
    id: 19,
    category: "Toys",
    categoryImage: TOYS,
  },
  {
    id: 20,
    category: "Jewelery",
    categoryImage: JEWELERY,
  },
  {
    id: 21,
    category: "Hats",
    categoryImage: CAP,
  },
];

export default function makeCategories(categories: any) {
  let _categories = [];

  for (let i = 0; i < categories.length; i++) {
    switch (categories[i].categoryName) {
      case " Animals & Pet Supplies":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: ANIMALS_AND_PET_SUPPLIES,
        });
        break;

      case " Arts & Entertainment":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: ART_AND_ENTERTAINMENT,
        });
        break;

      case " Apparel & Accessories":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: APPAREL_AND_ACCESSORIES,
        });
        break;

      case " Baby & Toddler":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: BABY_AND_TODDLER,
        });
        break;

      case " Business & Industrial":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: BUSSINESS_AND_INDUSTRIAL,
        });
        break;

      case " Electronics":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: ELECTRONICS,
        });
        break;

      case "Mobile Phone Pre-Paid Cards":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: MOBILE_PREPAID_CARDS,
        });
        break;
      case "E-Book Reader Cases":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: EBOOK_READER_CASES,
        });
        break;

      case "E-Book Reader Screens & Screen Digitizers":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: EREADER,
        });
        break;

      case " Food, Beverages & Tobacco":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: FOOD_BEVERAGES,
        });
        break;

      case " Furniture":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: FURNITURES,
        });
        break;

      case " Hardware":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: HARDWARE,
        });
        break;

      case " Health & Beauty":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: HEALTH_AND_BEAUTY,
        });
        break;
      case "Anti-Perspirant":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: ANTI_PERSPIRANT,
        });
        break;
      case " Home & Garden":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: GREEN_HOUSE,
        });
        break;

      case " Luggage & Bags":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: BAGS,
        });
        break;
      case " Mature":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: ART_AND_ENTERTAINMENT,
        });
        break;
      case " Media":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: MATURE,
        });
        break;

      case " Office Supplies":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: OFFICE_SUPPLIES,
        });
        break;
      case " Religious & Ceremonial":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: CEREMONIAL,
        });
        break;
      case " Software":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: SOFTWARE,
        });
        break;
      case " Sporting Goods":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: SPORTING_GOODS,
        });
        break;
      case " Toys & Games":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: TOYS,
        });
        break;
      case " Vehicles & Parts":
        _categories.push({
          id: categories[i].categoryId,
          categoryName: categories[i].categoryName,
          categoryImage: VEHICLES_AND_PARTS,
        });
        break;

      default:
        break;
    }
  }
  return _categories;
}

export { productCategories };
