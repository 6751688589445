import { call, put, takeLatest } from "redux-saga/effects";
import snack from "components/wrapper/snack";
import {
  uploadImageStart,
  uploadImageSuccess,
  uploadImageFailure,
} from "redux/imageUpload"; 
import {
  uploadDocs,
} from "redux/services/upload";
import { accountSettingsUpdateStart } from "redux/accountSettings";


function* uploadImageSagaWatcher() {
  console.log('uploadImageStart',uploadImageStart.type)
  yield takeLatest(
    [
      uploadImageStart.type,
    ],
    uploadImageWorker 
  );
}

function* uploadImageWorker(action: any): any {
  console.log('uploadDocumentsStart2',uploadImageStart.type)

  try {
    switch (action.type) {
      case uploadImageStart.type:
        {
          const response: any = yield call(uploadDocs, action.payload);
          yield put(
            uploadImageSuccess({ documents: response.ResponseBody })
          );
          yield put(accountSettingsUpdateStart({
            profileImageUrl : {profileImageUrl : response.ResponseBody[0].url},
          }))
          snack.success("Document uploaded successfully");
        }
        break;
      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  
    
    if (action.type === uploadImageStart.type) {
      snack.error(err?.response?.data?.msg);
      yield put(uploadImageFailure({ msg: err?.response?.data?.msg }));
    }

  }
}

export default uploadImageSagaWatcher;
