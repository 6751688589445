import { createSlice, current } from "@reduxjs/toolkit";
import { getAccessToken } from "utils/auth";

interface State {
  isLoading: boolean;
  cartItems: any;
  selectedProduct: any;
  cartBanner: number;
  saveCartItem: any;
  error: boolean;
}

const initialState: State = {
  isLoading: true,
  selectedProduct: {},
  cartItems: [],
  cartBanner: 0,
  saveCartItem: [],
  error: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCartStart: (state: State, action) => ({
      ...state,
      isLoading: true,
    }),
    addToCartSuccess: (state: State, action) => {
      let cartBanner = 0;
      if (getAccessToken()) {
        state.cartItems = action.payload.cartItems;

        for (let i = 0; i < state.cartItems.length; i++) {
          if (state.cartItems[i].saveForLater) {
            continue;
          }
          cartBanner += state.cartItems[i].cartQty;
        }

        state.cartBanner = cartBanner;
        state.isLoading = false;
      } else {
        state.cartItems = action.payload.cartItems;
        state.saveCartItem = action.payload.saveCartItem;

        for (let i = 0; i < state.saveCartItem.length; i++) {
          if (
            state.cartItems &&
            state.cartItems[i] &&
            state.cartItems[i].saveForLater
          ) {
            continue;
          }
          cartBanner += state.saveCartItem[i].quantity;
        }
        state.cartBanner = cartBanner;
        state.isLoading = false;
      }
    },
    addToCartFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    removeFromCartStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    removeFromCartSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
    }),
    removeFromCartFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    updateCartBanner: (state: State, action) => {
      state.cartBanner =
        action.payload.cartBanner - action.payload.item.quantity;
    },
    getCartItemsStart: (state: State) => ({
      ...initialState,
      isLoading: true,
    }),
    saveForLater: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    saveForLaterSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
    }),
    saveForLaterFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    clearCartData: (state: State) => ({
      ...state,
      cartItems: [],
      cartBanner: 0,
      isLoading: false,
      error: false,
    }),
    saveForLaterCheckout: (state: State, action) => ({
      ...state,
      cartItems: action.payload._product,
    }),
    updateCartItem: (state: any, action) => {
      const productId = action.payload.ResponseBody.productId;
      const currentState = current(state);
      const updateCartItems = currentState.cartItems.map( (item: any) => {
        if (productId === item._id) {
          const newItem = {
            ...item,
            cartQty: action.payload.ResponseBody.quantity,
          };
          return newItem
        }
        return item
      })
      return {
        ...state,
        cartItems: updateCartItems,
        isLoading: false
      };
    },
  },
});

export const {
  addToCartStart,
  addToCartSuccess,
  addToCartFailure,
  removeFromCartStart,
  removeFromCartSuccess,
  removeFromCartFailure,
  getCartItemsStart,
  clearCartData,
  saveForLater,
  updateCartBanner,
  saveForLaterSuccess,
  saveForLaterFailure,
  saveForLaterCheckout,
  updateCartItem,
} = cartSlice.actions;
export default cartSlice.reducer;
