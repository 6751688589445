import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  orders : any;
  orderCount: number;
  targetOrder: any;
}

const initialState: State = {
  isLoading: true,
  error : false,
  orders : [],
  orderCount: 0,
  targetOrder: []
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    createOrderStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    createOrderSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      orders : action.payload.orders.orderHistory,
      orderCount: action.payload.orders.orderCount
    }),
    createOrderFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getOrdersStart: (state:State, action) => ({
      ...initialState,
      isLoading : true
    }),
    getOrdersBySellerIdStart: (state:State, action) => ({
      ...initialState,
      isLoading : true
    }),
    getOrdersBySellerIdSuccess: (state:State, action) => ({
      ...initialState,
      isLoading : false,
      orders : action.payload.orders.orderHistory,
      orderCount: action.payload.orders.orderCount
    }),
    getOrdersBySellerIdFailure: (state:State, action) => ({
      ...state,
      error: true,
      isLoading : false,
    }),
    getOrderByOrderIdStart: (state:State, action) => ({
      ...initialState,
      isLoading : true
    }),
    getOrderByOrderIdSuccess: (state:State, action) => ({
      ...state,
      isLoading : false,
      // prev --> order : action.payload
      targetOrder: action.payload
    }),
    getOrderSuccess: (state: State, action) => ({
      ...initialState,
      isLoading: false,
      orders: action.payload.orders,
    }),
    updateOrderStatusSuccess:(state: State, action) => ({
      ...initialState,
      isLoading : true
    }),
    updateOrderStatusStart:(state: State, action) => ({
      ...initialState,
      isLoading : false
    }),
    updateOrderStatusFailure:(state: State, action) => ({
      ...state,
      error:true,
      isLoading : false
    })
  }
});

export const {
  createOrderStart,
  createOrderSuccess,
  createOrderFailure,
  getOrdersStart,
  getOrdersBySellerIdStart,
  getOrderByOrderIdStart,
  getOrdersBySellerIdSuccess,
  getOrdersBySellerIdFailure,
  getOrderByOrderIdSuccess,
  getOrderSuccess,
  updateOrderStatusSuccess,
  updateOrderStatusStart,
  updateOrderStatusFailure,
} = orderSlice.actions;
export default orderSlice.reducer;
