import { call, put, takeLatest } from "redux-saga/effects";
import {
  printShippingLabelFailure,
  printShippingLabelStart,
  printShippingLabelSuccess,
} from "redux/shipping";

import { getShippingLabel } from "redux/services/shipping";
import { downloadPDF } from "utils/downloadPdf";

function* shippingSagaWatcher() {
  yield takeLatest([printShippingLabelStart.type], shippingWorker);
}

function* shippingWorker(action: any): any {
  try {
    switch (action.type) {
      case printShippingLabelStart.type: {
        const response: any = yield call(getShippingLabel, action.payload);
        downloadPDF(response.data.ResponseBody)
        yield put(printShippingLabelSuccess({ response }));
      }                       
      break;
      default:
        break;
    }
  } catch (err:any) {
    yield put(printShippingLabelFailure());
    console.error(`Error occuring while calling an action ${action.type}`, err);
  }
}
export default shippingSagaWatcher;
