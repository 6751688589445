import React, { useState, SyntheticEvent, useRef } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import snack from "components/wrapper/snack";
import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./shop.css";
import Resizer from "react-image-file-resizer";
import { backgroundImageUploadStart } from "redux/backgroundImageUpload";
import "react-image-crop/dist/ReactCrop.css";
import { PROFILE_PIC } from "utils/constants/constants";
import { useDebounceEffect } from "./cropImage/useDebounceEffect";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./cropImage/canvasPreview";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(50% + 100px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 3840,
        height: 627,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function EditShopModal() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const userData: any = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [imgSrc, setImgSrc] = useState("");
  // const [cropedImage, setCropedImage] = useState<any>("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);

  const onUploadAttachments = (cropedImage:File[]) => {
    if (
      !userData.user.firstName ||
      !userData.user.lastName ||
      !userData.user.email
    ) {
      snack.error("Please Login");
    } else {
      if (cropedImage === null) {
        snack.error("Please select images to upload");
      } else {
        dispatch(
          backgroundImageUploadStart({
            documents: cropedImage,
            firstName: userData.user.firstName,
            lastName: userData.user.lastName,
            email: userData.user.email,
          })
        );
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    history.push("/dashboard");
  };
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const onDownloadCropClick = () => {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob: any) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      console.log('blobUrlRef.current',blobUrlRef.current)
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      const file = new File([blob], Math.random().toString(), {type: 'image/png'});
      onUploadAttachments([file]);
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );


  return (
    <div id="Modal">
      <Button variant="text" onClick={handleOpen}>
        Update background
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div id="modal-content">
            <h3 id="modal-modal-title">Edit Banner</h3>
            <div id="shop-edit-options">
              <div
                id="imagePlaceholder"
                data-et-name="profileEditorImageSection"
              >
                <div className="App">
                  <div className="Crop-Controls">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onSelectFile}
                    />
                    <div>
                      <label htmlFor="scale-input">Scale: </label>
                      <input
                        id="scale-input"
                        type="number"
                        step="0.1"
                        value={scale}
                        disabled={!imgSrc}
                        onChange={(e) => setScale(Number(e.target.value))}
                      />
                    </div>
                  </div>
                  {!!imgSrc && (
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={aspect}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"

                        src={imgSrc}
                        style={{
                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                          height:"200px",
                          width:"200px"
                        }}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  )}
                  {!!completedCrop && (
                    <>
                   <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                display:"none",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
          <div>
            <button onClick={onDownloadCropClick}>Update Background</button>
            <a
              ref={hiddenAnchorRef}
              download
              style={{
                position: 'absolute',
                top: '-200vh',
                visibility: 'hidden',
              }}
            >
              Hidden download
            </a>
          </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <p id="close-modal-text">Click anywhere outside window to close.</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
