import axios from 'api/axiosController';


export const getShippingLabel = async (shippingData:any) =>{
    let uri ='/shipping/printShippingLabel';
    try{
    const response = await axios.post(uri,shippingData);
    return response; 
    } catch(e){
        throw e;
    }
}