import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { accountSettingsUpdateStart } from "redux/accountSettings";
import styled from "styled-components";

const CenteredButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredButtonWithMargin = styled(CenteredButton)`
  margin-bottom: 15px;
`;


const TextArea = styled.textarea`
  display: flex;
  align-items: center;
  max-width: 70%;
  width: 100%;
  height: auto; 
  margin: 0 auto; 
`;

export default function EditDescription() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState({
    description: "",
  });

  const saveChanges = async (e: React.SyntheticEvent) => {
    e.preventDefault();
      dispatch(
        accountSettingsUpdateStart({
          description: name.description,
        })
      );

      history.push("/account-settings");
  };

  return (
    <div className="row">
      <div className="col-md-12 col-lg-12 detail-div">
        <form onSubmit={saveChanges}>
          <div>
            <CenteredButton>Edit your shop description</CenteredButton> &nbsp;&nbsp;&nbsp;&nbsp;
            <TextArea
              placeholder="Write a description for your shop. We accept Markdown Text (.md) text so feel free to add styles, boldness, colors, and even images!
              Use #, ##, and ### for headings
              `` for different font
              Images are like: ![image description](https://imageURL.png)"
              value={name.description}
              name="description"
              id="description"
              autoFocus
              style={{width:"100%",height:"300px"}}
              onChange={(e:any)=>setName({description:e.target.value})}
            />
          </div>
          <br />

          <CenteredButtonWithMargin className="save-btn">
              <button className="btn btn-primary" type="submit">
                Edit and Save Changes
              </button>
          </CenteredButtonWithMargin>
        </form>
      </div>
    </div>
  );
}
