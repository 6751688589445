import { call, put, takeLatest } from "redux-saga/effects";
import snack from "components/wrapper/snack";
import {
  uploadDocumentsStart,
  uploadDocumentsSuccess,
  uploadDocumentsFailure,
} from "redux/upload"; 
import {
  uploadDocs,
} from "redux/services/upload";


function* uploadDocumentSagaWatcher() {
  console.log('uploadDocumentsStart',uploadDocumentsStart.type)
  yield takeLatest(
    [
      uploadDocumentsStart.type,
    ],
    uploadDocumentWorker
  );
}

function* uploadDocumentWorker(action: any): any {
  console.log('uploadDocumentsStart2',uploadDocumentsStart.type)

  try {
    switch (action.type) {
      case uploadDocumentsStart.type:
        {
          const response: any = yield call(uploadDocs, action.payload);
          yield put(
            uploadDocumentsSuccess({ documents: response.ResponseBody })
          );
          snack.success("Document uploaded successfully");
        }
        break;
      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  
    
    if (action.type === uploadDocumentsStart.type) {
      snack.error(err?.response?.data?.msg);
      yield put(uploadDocumentsFailure({ msg: err?.response?.data?.msg }));
    }

  }
}

export default uploadDocumentSagaWatcher;
