import {_axiosInstance} from "api/axiosController";

export const gettravelProducts = async (query: number) => {
  let uri = `/search/v1/get?query=Travel`;
  try {
    const res:any = await _axiosInstance.get(uri);
    console.log ({travel : res })
    return res.data;
  } catch (e) {
    throw e;
  }
};

