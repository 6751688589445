import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import {
  getProductsFeedStart,
  getProductFeedSuccess,
  getProductFeedFailure
} from "redux/Home/homePageLoad";
import { getHomePageProducts } from "redux/services/Home/homePageProducts";

function* homeFeedSagaWatcher() {
  yield takeLatest(
    [getProductsFeedStart.type],
    homePageSagaWorker,
  );
}

function* homePageSagaWorker(action: any): any {
  try {
    switch (action.type) {
      case getProductsFeedStart.type: {
        const data = yield call(getHomePageProducts);
        yield put(getProductFeedSuccess({ 
          result: data.ResponseBody
        }));
      }
        break;

      default:
        break;
    }
  } catch (err:any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getProductsFeedStart.type) {
      yield put(getProductFeedFailure());
    }
  }
}

export default homeFeedSagaWatcher;
