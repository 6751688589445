import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Link, Modal } from "@mui/material";
import { useQuery, useSearch, useSearchContext } from "@sajari/react-hooks";
import logo from "assets2/images/logo/ReSell-Marketplace.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { getCartItemsStart } from "redux/cart";
import { RootState } from "redux/store";
import { getUserStart } from "redux/user";
import makeCategories from "utils/productCategories";
import HamburgerMenu from "./hamburgerMenu";
import Search from "./search";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import snack from "components/wrapper/snack";
import "../../../styles/common.css";
import "../../../styles/responsive-common.css";
import "./style.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Helmet } from "react-helmet";
import { FLAG } from "assets2/images";
import { getNotification } from "redux/notification";
import { socket } from "../../../socket";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";

const Header: React.FC = () => {
  const location: any = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);
  const { query, setQuery } = useQuery();
  const { results = [] } = useSearch();
  const { searched } = useSearchContext();
  const userData: any = useSelector((state: RootState) => state.user);
  let categories = useSelector(
    (state: RootState) => state.categories.categoryList
  );
  const cartBanner = useSelector((state: RootState) => state.cart.cartBanner);
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  );
  const wishlistBanner = useSelector(
    (state: RootState) => state.wishlist.wishlistBanner
  );
  const user: any = useSelector((state: RootState) => state.user.user);
  const accessToken: any = useSelector(
    (state: RootState) => state.user.accessToken
  );

  const [reload, setReload] = useState(true);

  categories = categories.length > 0 ? makeCategories(categories) : [];

  if (user._id) {
    socket.on("notify-user-" + user._id, (_) => {
      dispatch(getNotification({ userId: user._id }));
    });
  }

  useEffect(() => {
    if (user._id && reload) {
      dispatch(getNotification({ userId: user._id }));
      setReload(false);
    }
  });

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    dispatch(getUserStart());
    dispatch(getCartItemsStart());
  };

  const notificationBanner = () => {
    const unread = notifications.filter(
      (notification: any) => !notification.isRead
    );
    return unread.length;
  };

  const settings = {
    dots: false,
    infinite: true,

    slidesToShow: 11,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <FontAwesomeIcon icon={faChevronRight as IconProp} />,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft as IconProp} />,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          dots: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          dots: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const userComp = () => {
    return (
      <>
        <Link aria-label="User dashboard">
          {/* onClick={handleMenu} */}
          {/* <PersonOutlineOutlinedIcon style={{ fontSize: "36px" }} /> */}
          <Helmet>
            <title>{`Resell  ${"Marketplace" || query}`}</title>
            <meta
              name="description"
              content={`Resell : ${location?.state?.query || query}`}
            />
          </Helmet>
          {accessToken && accessToken !== null ? (
            <div className="cursorPointer">
              <Avatar
                sx={{
                  bgcolor: grey[600],
                  marginLeft: "10px",
                  fontWeight: "normal",
                  "@media (max-width: 600px)": {
                    marginLeft: "4px",
                    width: "30px",
                    height: "30px",
                    fontSize: "14px",
                  },
                }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push({ pathname: "/dashboard" });
                }}
              >
                {user.firstName ? user.firstName[0] : null}
                {user.lastName ? user.lastName[0] : null}
              </Avatar>
              <span
                className="header-text"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push({ pathname: "/dashboard" });
                }}
              >
                Dashboard
              </span>
            </div>
          ) : (
            <div className="cursorPointer">
              <PersonOutlineOutlinedIcon
                style={{ fontSize: "41px" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push({ pathname: "/signin" });
                }}
              />
              <span
                className="header-text"
                id="register-label"
                onClick={() => {
                  history.push("/signin");
                }}
              >
                Sign In
              </span>
            </div>
          )}
        </Link>
      </>
    );
  };

  const handleChangeCategory = (category: any) => {
    setQuery(category.categoryName);
    history.push({
      pathname: "/search-results",
      state: {
        searched: searched,
        results: results,
      },
    });
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
  };

  const openMarketPlace = () => {
    if (
      !userData.user.firstName ||
      !userData.user.lastName ||
      !userData.user.email
    ) {
      snack.error("Login First");
    } else {
      history.push({
        pathname: "/listing",
      });
    }
  };

  return (
    <>
      <header>
        <div className="header-content">
          <div className="logo-wrap d-flex align-items-center">
            <Link className="brand-logo mr-3" href="/">
              <img src={logo} alt="Brand Logo" />
            </Link>
            <div className="hamburger-text">
              <div className="position-hamburger">
                <HamburgerMenu
                  userName={
                    user.firstName ? user.firstName.toUpperCase() : null
                  }
                />
              </div>
              <p className="hamburger-label">Categories</p>
            </div>
          </div>

          <div className="header-search d-none mr-3 d-lg-block">
            <div>
              <div className="form-group search-provider">
                <Search />
              </div>
            </div>
          </div>
          <div className="heder-right">
            <ul>
              <li className="header-right__wishlist">
                <Link
                  onClick={() => history.push("/wishlist")}
                  className="cursorPointer"
                >
                  <Badge
                    invisible={accessToken ? false : true}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={wishlistBanner}
                    color="secondary"
                    showZero
                    sx={{
                      "& .MuiBadge-badge": {
                        minHeight: "54%",
                        width: "48%",
                        marginLeft: "25%",
                      },
                    }}
                  >
                    <FavoriteBorderIcon
                      style={{ fontSize: "40px" }}
                      id="favorite-icon"
                    />
                  </Badge>
                  <span className="header-text">Wishlist</span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => history.push("/cart")}
                  className="cursorPointer"
                >
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={cartBanner}
                    color="secondary"
                    showZero
                    sx={{
                      "& .MuiBadge-badge": {
                        minHeight: "54%",
                        width: "54%",
                        marginLeft: "20%",
                      },
                    }}
                  >
                    <ShoppingCartOutlinedIcon style={{ fontSize: "40px" }} />
                  </Badge>
                  <span className="header-text">Cart</span>
                </Link>
              </li>
              {accessToken ? (
                <li>
                  <Link
                    onClick={() => history.push("/notification")}
                    className="cursorPointer"
                  >
                    <Badge
                      invisible={accessToken ? false : true}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={notificationBanner()}
                      color="secondary"
                      showZero
                      sx={{
                        "& .MuiBadge-badge": {
                          minHeight: "40%",
                          width: "32%",
                          marginLeft: "30%",
                        },
                      }}
                    >
                      <NotificationsNoneIcon style={{ fontSize: "40px" }} />
                    </Badge>
                    <span className="header-text">Notification</span>
                  </Link>
                </li>
              ) : null}
              <li>{userComp()}</li>
            </ul>
            <div className="header-flag">
              <Link className="cursorPointer">
                <img alt="flag" src={FLAG} />
                <KeyboardArrowDownOutlinedIcon
                  style={{ color: "#000" }}
                  className="arrow-img"
                />
              </Link>
            </div>
            <Link
              onClick={openMarketPlace}
              style={{ padding: "10px 14px" }}
              className="btn btn-primary"
              href={userData.user.firstName ? "/listing" : "/signin"}
            >
              Sell
            </Link>
            <Modal open={open} onClose={handleModalClose}>
              <Box sx={style}>
                <div>
                  Please email us at <Link>info@resell.ooo</Link> with your
                  company info, types of products you sell, contact person and a
                  phone number.
                </div>
                <button className="btn btn-primary" onClick={handleModalClose}>
                  Close
                </button>
              </Box>
            </Modal>
          </div>
          <hr />
        </div>
      </header>
      <div
        className="header-search mobile-search"
        style={
          location.pathname !== "/" ? { display: "none" } : { display: "block" }
        }
      >
        <div className="form-group search-provider">
          <Search />
          <br />
        </div>
      </div>

      <section className="category-section d-block d-lg-none">
        <div className="category-slider-wrap">
          <Slider {...settings}>
            {categories &&
              categories.map((item: any, index: number) => (
                <div className="category-slider-card" key={item.id}>
                  <Link onClick={() => handleChangeCategory(item)}>
                    {item.categoryImage ? (
                      <img src={item.categoryImage} alt="Category Image" />
                    ) : null}
                    <span>{item.categoryName}</span>
                  </Link>
                </div>
              ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Header;
