//eslint-disable-next-line react-hooks/exhaustive-deps
import React from "react";
import { Link, Drawer, Fab } from "@mui/material";
import { useEffect, useState } from "react";
import Menu from "./menu";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesStart } from "redux/categories";
import { RootState } from "redux/store";
import { useHistory } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface InputProps {
  userName: string;
}

export default function HamburgerMenu({ userName }: InputProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openHamburger, setOpenHamburger] = useState<boolean>(false);
  const fabItemRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    dispatch(fetchCategoriesStart());
  }, [dispatch]);

  const categories = useSelector(
    (state: RootState) => state.categories.categoryList
  );

  const handleCloseHamburger = () => {
    setOpenHamburger(false);
  };

  const handleOpenHamburger = () => {
    setOpenHamburger(true);
  };

  const initialCategories = () => {
    return (
      <ul className="navbar-nav">
        {categories.length &&
          categories.map((item: any, index: number) => (
            <li key={index}>
              <Menu {...item} setOpenHamburger={setOpenHamburger} />
            </li>
          ))}
      </ul>
    );
  };

  return (
    <>
      <Link
        onClick={handleOpenHamburger}
        className="toggle-bar-icon cursorPointer"
        aria-label="hamburger menu"
      >
        <i className="fas fa-bars"></i>
      </Link>
      <Drawer onClose={handleCloseHamburger} open={openHamburger}>
        <div className=" mobile-view">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <Link
              className="close-menu"
              onClick={handleCloseHamburger}
              aria-label="hamburger menu"
            >
              <i className="fas fa-times"></i>
            </Link>
            <div className="top-fixed-section">
              <ul>
                <li className="user-tab">
                  {userName ? (
                    <p className={"white-para"}>Hello {userName}</p>
                  ) : (
                    <Link
                      onClick={() => {
                        history.push("/signin");
                        handleCloseHamburger();
                      }}
                    >
                      Sign-In
                    </Link>
                  )}
                </li>
                <li className="menu-tab">All Categories</li>
              </ul>
            </div>

            <div className="inner-menu-section">
              <div
                ref={fabItemRef}
                className="top-fixed-section-fab-button"
              ></div>
              <Link>{initialCategories()}</Link>
              <div ref={fabItemRef} className="bottom-fixed-section-fab-button">
                <Fab
                  onClick={() =>
                    fabItemRef.current!.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    })
                  }
                  color="primary"
                  aria-label="add"
                >
                  <ArrowDownwardIcon />
                </Fab>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
