import "../style.css";
import { PROFILE_PIC } from "utils/constants/constants";

const ShopTileLayout = ({ shop, handleClick }: any) => {
  return (
    <div id="shop-tile-container">
      <div id="shop-tile-banner">
        <img
          src={shop?.profileImage ? shop?.profileImage : PROFILE_PIC}
          alt="ShopBanner"
          height={200}
          onClick={() => handleClick(shop)}
        />
      </div>
      <h3 onClick={() => handleClick(shop)}>{shop?.username}</h3>
    </div>
  );
};    

export default ShopTileLayout;
