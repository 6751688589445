import { createSlice } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  error: boolean;
  Offers: any;
}

const initialState: State = {
  isLoading: true,
  error: false,
  Offers: [],
};

export const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: { 
    getOffersStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    getOffersSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      Offers: action.payload.Offers, 
    }),
    getOffersFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    updateOffersStatusStart: (state: State,action) => ({
      ...state,
      isLoading: false,
    }),
    updateOffersStatusSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      Offers: action.payload.Offers
    }),
    updateOffersStatusFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
  },
});

export const { getOffersStart, getOffersSuccess, getOffersFailure,updateOffersStatusStart, updateOffersStatusSuccess, updateOffersStatusFailure } =
  offerSlice.actions;
export default offerSlice.reducer;
