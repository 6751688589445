import {
  useSearch,
  useQuery,
  useSearchContext,
  useAutocomplete,
} from "@sajari/react-hooks";
import { Combobox } from "@sajari/react-components";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import useComponentVisible from "./setVisible";
import { useEffect } from "react";
import  "../SiteContent/Listing/";
import { QUERY_NAME } from "utils/constants/constants";

export default function Search() {
  const history = useHistory();
  const { results } = useSearch();
  const { suggestions, search: searchInstant } = useAutocomplete();
  const { query, setQuery } = useQuery();
  const { searched } = useSearchContext();
  const [searchClicked, setSearchClicked] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  
  const MOBILE_BREAKPOINT = 620;
  const width = window.innerWidth;

  const handleSearch = (value: string) => {
    setSearchClicked(true);
    history.push({
      pathname: "/search-results",
      search:`?${QUERY_NAME}=${value}`,
      state: {
        value,
        searched,
        results,
      },
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (query) {
        handleSearch(query);
      }
    }
  };

  const comboboxOnChange = (value: any) => {
    if(query && value === '') return
    searchInstant(value);
    if (searchClicked) setSearchClicked(false);
    setQuery(value);
    setIsComponentVisible(true);
  };

  useEffect(() => {
    if (history.location.pathname !== "/search-results") setQuery("");
  }, [history.location.pathname]);

  return (
    <div style={{ zIndex: 1000 }}>
      <Combobox
        size="lg"
        mode="suggestions"
        name="query"
        label="Query"
        value={query}
        onKeyDown={handleKeyDown}
        onChange={comboboxOnChange}
      />
      {
        width > MOBILE_BREAKPOINT ? (
          <>
      <button
        type="button"
        onClick={() => {
          if (query) {
            handleSearch(query);
          }
        }}
        className="btn btn-primary header-search-btn"
        aria-label="search-bar-search"
      >
        <i className="fas fa-search"></i>
      </button>
      </>
        ): ''
      }
      <div ref={ref}>
        {query &&
          suggestions &&
          suggestions.length > 0 &&
          (!searchClicked && isComponentVisible ? (
            <ul className="list-disc px-5 space-y-2 mt-5">
              {suggestions &&
                suggestions.map((item: any, index: number) => (
                  <li
                    key={item}
                    onClick={() =>{
                      setQuery(item);
                        handleSearch(item);
                    }
                    }
                  >
                    <p>{item}</p>
                  </li>
                ))}
            </ul>        
         ) : null)}
      </div> 
    </div>
  );
}
